import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { insightExpandToggle } from "../constants/dashUI-actions";
import InsightInfoIcon from "./insight-info-icon";
import { dbColors } from "../constants/dbColors.js";
import {
  BikeIcon,
  Co2Icon,
  DistanceIcon,
  AvgSpeedIcon,
} from "../constants/iconIndex.js";

import PanelCrossIcon from "../icon/panel-cross";
import PanelArrowIcon from "../icon/panel-arrow";

const Insight = styled.div.attrs((props) => ({
  width: props.width || "350px",
}))`
  width: ${(props) => props.width};
  height: ${(props) => (props.heightControl ? "fit-content" : "60px")};

  display: block;
  position: relative;
  margin: 0em 0em 0.6em 0em;
  overflow: hidden;

  background-color: ${dbColors.background};
  color: ${dbColors.highlight};
  font-family: Roboto, sans-serif;
  border-radius: 7px;
  opacity: 1;

  box-shadow: 3px 3px 5px 0px #00000014;
  transition: height 0.1s;
`;

const InsightIcon_OuterContainer = styled.div``;
const InsightIcon_Container = styled.div`
  padding: 7px 0px 0px 10px;
`;

const InsightHeader_Container = styled.div`
  width: auto;
  height: 70px;
  margin: 0px 15px 0px 0px;
  padding: 0;
  float: left;
  clear: none;
`;

const InsightTitle = styled.p`
  font-size: 1.25em;
  font-weight: bold;

  margin: 0;
  margin-left: 10px;
  margin-top: ${(props) => (props.topMargin ? "0px" : "31px")};
`;

const InsightNumber_Container = styled.div`
  width: fit-content;
  height: 60px;

  margin: 0;
  padding: 0;
  float: left;
  clear: none;
`;

const InsightNumber_Number = styled.p`
  font-weight: bold;
  font-size: 2.5em;

  margin: 0;
  margin-top: 14px;
  float: left;
`;
const InsightNumber_Unit = styled.p`
  float: left;
  clear: none;

  margin: 36px 0px 0px 5px;
`;

const InsightDescription_Container = styled.div`
  margin: 0;
  padding: 0px 10px 10px 10px;
  float: left;
  clear: both;
`;
const InsightToggle_Container = styled.div`
  height: 45px;
  width: 45px;
  position: absolute;
  right: 0px;
  cursor: pointer;
`;

class InsightPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  iconHelper = () => {
    switch (this.props.info.title) {
      case "Active Bikes":
        return <BikeIcon fill={dbColors.highlight} height={"20px"} />;
        break;
      case "Total Kilometres":
        return <DistanceIcon fill={dbColors.highlight} height={"20px"} />;
        break;
      case "Avg. Speed":
        return <AvgSpeedIcon fill={dbColors.highlight} height={"20px"} />;
        break;
      case "CO2 Saved":
        return <Co2Icon fill={dbColors.highlight} height={"20px"} />;
        break;
      default:
        return <p>ICON</p>;
    }
  };

  render() {
    return (
      //JSX code for component
      <Insight
        id={`insight-panel-${this.props.info.insightId}`}
        width={this.props.width}
        heightControl={
          this.props.dashboard.dbUiState.insightPanels[
            this.props.info.insightId
          ].active
        }
      >
        <InsightHeader_Container id={"insight-header_container"}>
          {this.props.renderIcon && (
            <InsightIcon_Container id="insight-icon_container">
              {this.iconHelper()}
            </InsightIcon_Container>
          )}
          <InsightTitle id={"insight_title"} topMargin={this.props.renderIcon}>
            {this.props.info.title}
          </InsightTitle>
        </InsightHeader_Container>
        <InsightNumber_Container id={"insight-number_container"}>
          <InsightNumber_Number id={"insight-large-number"}>
            {this.props.data}
          </InsightNumber_Number>
          <InsightNumber_Unit id={"insight-large-number-unit"}>
            {this.props.info.unit}
          </InsightNumber_Unit>
        </InsightNumber_Container>
        {this.props.dashboard.dbUiState.insightPanels[this.props.info.insightId]
          .active &&
          this.props.enableDropdown && (
            <InsightDescription_Container id={"insight-description_container"}>
              {this.props.info.InsightDescription}
            </InsightDescription_Container>
          )}
        {this.props.enableDropdown && (
          <InsightToggle_Container
            onClick={() =>
              this.props.insightExpandToggle(this.props.info.insightId)
            }
            id={"insight-toggle_container"}
          >
            {this.props.dashboard.dbUiState.insightPanels[
              this.props.info.insightId
            ].active ? (
              <PanelCrossIcon fill={dbColors.highlight} />
            ) : (
              <PanelArrowIcon fill={dbColors.highlight} />
            )}
          </InsightToggle_Container>
        )}
        {this.props.displayInfoIcon && <InsightInfoIcon link={this.props.link}/>}
      </Insight>
    );
  }
}

InsightPanel.defaultProps = {
  renderIcon: true,
  info: { number: 70 },
  data: "...",
  enableDropdown: true,
  displayInfoIcon: false,
};

const mapStateToProps = (state) => {
  return state;
};
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, { insightExpandToggle })(InsightPanel);
