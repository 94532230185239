import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { togglePerspective, layerConfigChange } from "kepler.gl/actions";
import { panelExpandToggle } from "../constants/dashUI-actions";
import { dbColors } from "../constants/dbColors.js";
import LayerIcon from "./layerIcon";

import PanelCrossIcon from "../icon/panel-cross";
import PanelArrowIcon from "../icon/panel-arrow";

//COLOR DEFINITIONS
const background_LayerOff = dbColors.background;
const fill_LayerOff = dbColors.highlight;
const background_LayerOn = dbColors.highlight;
const fill_LayerOn = dbColors.background;

const MainLayerPanel = styled.div`
  width: 16em;
  height: ${(props) => (props.divheight ? "fit-content" : "45px")};
  transition: height 0.2s;
  border-radius: 7px;
  margin: 0.6em 0em 0em 1em;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.fill};
  border-style: none;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  overflow: hidden;
  display: ${(props) => props.viz};
  opacity: 0.9;
`;

class LayerToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  //insert any functions for the class and for the component
  layerSwitch = () => {
    if (
      this.props.info.title === "Dwell Time" &&
      this.props.keplerGl.map1.mapState.dragRotate === false &&
      this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl]
        .config.isVisible === false
    ) {
      this.props.togglePerspective();
    }

    const isVisible = !this.props.keplerGl.map1.visState.layers[
      this.props.info.layerToControl
    ].config.isVisible;
    this.props.layerConfigChange(
      this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl],
      { isVisible }
    );
  };

  bgHelper = () => {
    if (this.props.keplerGl.map1 !== undefined) {
      if (
        this.props.keplerGl.map1.visState.layers[
          this.props.info.layerToControl
        ] !== undefined &&
        this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl]
          .config.isVisible === true
      ) {
        return background_LayerOn;
      } else {
        return background_LayerOff;
      }
    }
    return;
  };

  fillHelper = () => {
    if (this.props.keplerGl.map1 !== undefined) {
      if (
        this.props.keplerGl.map1.visState.layers[
          this.props.info.layerToControl
        ] !== undefined &&
        this.props.keplerGl.map1.visState.layers[this.props.info.layerToControl]
          .config.isVisible === true
      ) {
        return fill_LayerOn;
      } else {
        return fill_LayerOff;
      }
    }
    return;
  };

  uiVisibilityHelper = () => {
    if (this.props.keplerGl.map1 === undefined) {
      return "block";
    } else {
      // console.log(this.props.keplerGl.map1.uiState.currentModal);
      if (this.props.keplerGl.map1.uiState.currentModal === "exportImage") {
        return "none";
      } else {
        return "block";
      }
    }
  };

  //{this.iconFillHelper(this.props.info.title, this.fillHelper())}
  render() {
    return (
      //JSX code for component
      <MainLayerPanel
        divheight={
          this.props.dashboard.dbUiState.layerPanels[this.props.info.panelId]
            .active
        }
        bg={this.bgHelper()}
        fill={this.fillHelper()}
        viz={this.uiVisibilityHelper()}
      >
        <div
          onClick={() => this.layerSwitch()}
          className={"layerPanelHeaderContentContainer"}
          style={{
            width: "80%",
            height: "100%",
            float: "left",
            clear: "none",
          }}
        >
          <div className={"layerpanelHeaderIcon"}>
            <LayerIcon title={this.props.info.title} fill={this.fillHelper()} />
          </div>
          <p
            className={"layerPanelHeaderTitle"}
            style={{
              padding: "10px",
              fontSize: "large",
              margin: 0,
              fontWeight: 400,
              float: "left",
              clear: "none",
            }}
          >
            {this.props.info.title}
          </p>
          <p
            className={"layerPanelHeaderContent"}
            style={{
              width: "230px",
              margin: "0px 10px",
              fontSize: "small",
              float: "left",
              clear: "none",
              paddingBottom: "10px",
            }}
          >
            {this.props.info.layerDescription}
            <br />
            {this.props.displayUrl &&
              <a
              href={this.props.info.infoUrl}
              target="_blank"
              style={{ color: "inherit" }}
            >
              Find out more
            </a>}
          </p>
        </div>
        <div
          onClick={() => this.props.panelExpandToggle(this.props.info.panelId)}
          className={"layerPanelExpandToggleContainer"}
          style={{
            width: "20%",
            left: "80%",
            float: "left",
            clear: "none",
            height: "fit-content",
          }}
        >
          {this.props.dashboard.dbUiState.layerPanels[this.props.info.panelId]
            .active ? (
            <PanelCrossIcon fill={this.fillHelper()} />
          ) : (
            <PanelArrowIcon fill={this.fillHelper()} />
          )}
        </div>
      </MainLayerPanel>
    );
  }
}

LayerToggle.defaultProps = {
  info: {
    title: "Layer Title",
    layerToControl: null,
    panelId: "panelDefault",
    layerDescription: "Layer description",
  },
  displayUrl: true,
};

const mapStateToProps = (state) => {
  return state;
};
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, {
  togglePerspective,
  layerConfigChange,
  panelExpandToggle,
})(LayerToggle);
