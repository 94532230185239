//hex layer icons
export {default as RoutePopularityIcon} from "../icon/routePopularityIcon";
export {default as SpeedIcon} from "../icon/speedIcon";
export {default as RoadSurfaceIcon} from "../icon/roadSurfaceIcon";
export {default as JourneysIcon2} from "../icon/journeysIcon2";
export {default as DwellTimeIcon} from "../icon/dwellTimeIcon";
export {default as BrakingIcon} from "../icon/brakingIcon";
export {default as SwervingIcon} from "../icon/swervingIcon";
export {default as PerceptionIcon} from "../icon/perceptionIcon";
// change from /sustransIcon2 back to /sustransicon for actual sustrans hex icon
export {default as SustransIcon} from "../icon/sustransIcon";
export {default as JourneysGridIcon} from "../icon/journeysGridIcon";

//insight icons
export {default as BikeIcon} from "../icon/bikeIcon3";
export {default as Co2Icon} from "../icon/co2Icon2";
export {default as DistanceIcon} from "../icon/distanceIcon";
export {default as AvgSpeedIcon} from "../icon/avgspeedIcon";

//nav icons
