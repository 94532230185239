import React from "react";

const DwellTimeIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
  margin = "",
  float = "left",
  clear = "none"
}) => (
  <svg
  width={width}
  style={style}
  height={width}
  viewBox={viewBox}
  style={style}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
<path fill={fill} d="M227.56,57.15L131.98,1.97C129.71,0.66,127.17,0,124.63,0c-2.54,0-5.08,0.66-7.35,1.97L21.7,57.15
  c-4.55,2.63-7.35,7.48-7.35,12.73v110.36c0,5.25,2.8,10.11,7.35,12.73l95.58,55.18c2.27,1.31,4.81,1.97,7.35,1.97
  c2.54,0,5.08-0.66,7.35-1.97l95.58-55.18c4.55-2.63,7.35-7.48,7.35-12.73V69.89C234.91,64.63,232.11,59.78,227.56,57.15z
   M227.56,180.25c0,2.62-1.41,5.06-3.68,6.37l-95.58,55.18c-1.12,0.64-2.39,0.99-3.68,0.99c-1.29,0-2.56-0.34-3.68-0.99
  l-95.58-55.18c-2.27-1.31-3.68-3.75-3.68-6.37V69.89c0-2.62,1.41-5.06,3.68-6.37l95.58-55.18c1.12-0.64,2.39-0.99,3.68-0.99
  c1.29,0,2.56,0.34,3.68,0.99l95.58,55.18c2.27,1.31,3.68,3.75,3.68,6.37V180.25z"/>
<path fill={fill} d="M124.63,83.32h-3.68v43.15c0,0.04,0,0.08-0.06,0.12c-0.06,0.03-0.08,0.02-0.12,0c-11.13-5.68-34.83-17.77-35.19-17.95
  l-3.27-1.67l-1.67,3.27c-4.35,8.5-6.11,18.1-5.1,27.74c2.38,22.83,20.67,41.24,43.48,43.75c1.83,0.2,3.66,0.3,5.48,0.3
  c12.16,0,23.96-4.44,33.04-12.57C168,160.11,174,146.71,174,132.69C174,105.47,151.85,83.32,124.63,83.32z"/>
<path fill={fill} d="M128.31,70.58v-8.01h9.94c2.03,0,3.68-1.65,3.68-3.68s-1.65-3.68-3.68-3.68h-27.23c-2.03,0-3.68,1.65-3.68,3.68
  s1.65,3.68,3.68,3.68h9.94v8.01c-15.78,0.95-30.8,7.93-41.67,19.5c-11.88,12.64-17.83,29.18-16.75,46.57
  c1.94,31.21,26.93,56.2,58.14,58.14c1.34,0.08,2.68,0.12,4.01,0.12c15.91,0,30.89-5.91,42.56-16.88
  c12.47-11.72,19.62-28.25,19.62-45.35C186.86,99.61,160.91,72.49,128.31,70.58z M162.2,172.68
  c-11.15,10.48-25.74,15.72-41.08,14.77c-13.21-0.82-25.72-6.51-35.23-16.02c-9.51-9.51-15.2-22.02-16.02-35.23
  c-0.95-15.34,4.29-29.93,14.77-41.08c10.49-11.16,24.69-17.3,39.99-17.3c30.26,0,54.87,24.61,54.87,54.87
  C179.5,147.99,173.36,162.19,162.2,172.68z"/>
</svg>

);

export default DwellTimeIcon;
