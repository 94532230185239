import React from "react";

const BikeIcon = ({
  style = {},
  fill = "#000000",
  width = "60px",
  height = "25px",
  className = "",
  viewBox = "0 0 60 30"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path  fill={fill} d="M36.07,12.14a9,9,0,0,0-2.81.49L31.73,8.27l-.25-1.69,2.89-.07c.41,0,1.38.08,1.55.72a1.06,1.06,0,0,1-.75,1.29,1,1,0,1,0,.5,1.84,3,3,0,0,0,2.1-3.63c-.36-1.33-1.63-2.12-3.41-2.13l-4,.09a.92.92,0,0,0-.71.34,1,1,0,0,0-.22.76l.28,1.78H16.76L16,5.16h2.58a1,1,0,0,0,0-1.91H12.67a1,1,0,1,0,0,1.91H14L15.47,9.9l-2.31,3.35a8.77,8.77,0,0,0-4.23-1.11,8.93,8.93,0,1,0,8.5,11.54,3,3,0,1,0,5.42-2.31l8.46-8.54.21.6a8.87,8.87,0,1,0,4.55-1.29Zm-19.88.09,2.43,8a2.9,2.9,0,0,0-.77.71,8.87,8.87,0,0,0-3.11-6.57ZM16,21.06l-5.35-.73,3.05-4.42A7,7,0,0,1,16,21.06Zm-7,7a7,7,0,0,1,0-14,7,7,0,0,1,3.15.78l-3.93,5.7a.94.94,0,0,0-.09.93A1,1,0,0,0,8.8,22l6.87.93A7,7,0,0,1,8.93,28.09Zm11.28-4.35a1.06,1.06,0,0,1,0-2.11,1.06,1.06,0,1,1,0,2.11ZM21.5,20a2.77,2.77,0,0,0-1-.28L17.34,9.48H30.15l.46,1.34Zm14.57,8.06a7,7,0,0,1-3.92-12.84l1,2.76a.66.66,0,0,0,.09.19l2,3.37a1,1,0,0,0,.82.45.93.93,0,0,0,.5-.14.94.94,0,0,0,.31-1.31l-2-3.28-1-2.86a6.9,6.9,0,0,1,2.19-.38,7,7,0,1,1,0,14Z" transform="translate(0 -3.25)"/>
  </svg>
);

export default BikeIcon;
