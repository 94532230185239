import React from "react";
import styled from "styled-components";

const ContainerColumnDiv = styled.div`
  display: ${props => props.display || "inline-block"};
  width: fit-content;
  height: fit-content;
  position: relative;
  z-index: 2;
  padding-top: 0.4em;
  pointer-events: auto;
  font-family: Roboto, sans-serif;
`;

const ContainerColumn = ({id, display, children}) => {
  return <ContainerColumnDiv id={id} display={display}>{children}</ContainerColumnDiv>;
};

export default ContainerColumn;