import React from "react";

const DistanceIcon = ({
  style = {},
  fill = "#000000",
  width = "60px",
  height = "25px",
  className = "",
  viewBox = "0 0 60 30"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M10.2,29.7c-0.4,0-0.7-0.2-0.9-0.5L1.6,14.8c0,0,0,0,0,0c-0.8-1.5-1.3-3.1-1.3-4.8c0-5.4,4.4-9.8,9.8-9.8S20,4.5,20,9.9
      c0,1.8-0.5,3.5-1.4,5L11,29.1C10.9,29.5,10.5,29.7,10.2,29.7z M3.3,13.7C3.3,13.7,3.3,13.8,3.3,13.7l6.9,12.8l6.7-12.6
      c0.7-1.2,1.1-2.6,1.1-4c0-4.3-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8C2.3,11.3,2.7,12.6,3.3,13.7z"/>
    <path fill={fill} d="M37.3,17.5c-0.4,0-0.7-0.2-0.9-0.5l-4.3-8C31.7,8,31.4,7,31.4,6c0-3.3,2.7-5.9,5.9-5.9c3.3,0,5.9,2.7,5.9,5.9
      c0,1.1-0.3,2.1-0.8,3l-4.2,7.9C38,17.3,37.7,17.5,37.3,17.5z M37.3,2.1c-2.2,0-3.9,1.8-3.9,3.9c0,0.7,0.2,1.3,0.5,1.9l3.4,6.4
      l3.4-6.3c0,0,0-0.1,0.1-0.1c0.3-0.6,0.5-1.2,0.5-1.9C41.3,3.9,39.5,2.1,37.3,2.1z"/>
    <path fill={fill} d="M10.2,13.8c-2.2,0-3.9-1.8-3.9-3.9C6.2,7.8,8,6,10.2,6c2.2,0,3.9,1.8,3.9,3.9C14.1,12.1,12.3,13.8,10.2,13.8z M10.2,8
      C9.1,8,8.2,8.9,8.2,9.9c0,1.1,0.9,1.9,1.9,1.9c1.1,0,1.9-0.9,1.9-1.9C12.1,8.9,11.2,8,10.2,8z"/>
    <path fill={fill} d="M18.5,29.7c-0.1,0-0.1,0-0.2,0l-4-0.1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1l4,0.1c1.5,0.1,3-0.8,3.6-2.2l3.3-6.7
      c1-2,3-3.3,5.3-3.3h2.7c0.6,0,1,0.4,1,1s-0.4,1-1,1h-2.7c-1.5,0-2.9,0.8-3.5,2.2l-3.3,6.7C22.9,28.4,20.8,29.7,18.5,29.7z"/>
  </svg>
);

export default DistanceIcon;
