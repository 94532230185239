import React from "react";
import styled from "styled-components";
import { dbColors } from "../constants/dbColors.js";

const InfoIcon = styled.span`
  position: absolute;
  right: 3px;
  bottom: 0px;
  margin: 0;
  padding: 0;
  font-size: small;
  }
`;

const InfoIconLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  :visited {
    color: inherit;
  }
  :hover {
    color: ${dbColors.highlight_hover};
  }
`;

const InsightInfoIcon = ({
  link = "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard",
}) => {
  return (
    <InfoIcon title="How we calculate insights">
      <InfoIconLink href={link} target={"_blank"}>
        &#9432;
      </InfoIconLink>
    </InfoIcon>
  );
};

export default InsightInfoIcon;
