import React from "react";
import ReloadArrowIcon from "../icon/reload-arrow";
import styled from "styled-components";

const ReloadPageDiv = styled.div`
width: 25px;
height: 25px;
float: right;
margin: 1em 1em 1em 0em;
cursor: pointer;
`;

const PageReloadButton = () => {
  return (
    <ReloadPageDiv onClick={() => window.location.reload()}>
      <ReloadArrowIcon />
    </ReloadPageDiv>
  );
};

export default PageReloadButton;
