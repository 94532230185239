import React from "react";

import LayerToggle from "./layer-toggle";
import { dbUiInfoCyclingIreland } from "../cyclingIreland/constants/uiInfo";
import ContainerColumn from "./dashboard-control-container-column";

const CyclingIrelandLayerUiContainer = (props) => {
  return (
    <ContainerColumn id="layer-controls--column_container">
      <LayerToggle info={dbUiInfoCyclingIreland.routePopularityPanelInfo} displayUrl={false}/>
      <LayerToggle info={dbUiInfoCyclingIreland.speedPanelInfo} displayUrl={false}/>
      <LayerToggle info={dbUiInfoCyclingIreland.dwellTimePanelInfo} />
    </ContainerColumn>
  );
};

export default CyclingIrelandLayerUiContainer;
