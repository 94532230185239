import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import PageReloadButton from "./page-reload-button";
import ExportButton from "./export-button";
import { dbColors } from "../constants/dbColors";

const MainTopBar = styled.div`
  position: absolute;
  float: left;
  clear: none;
  z-index: 2;
  width: 100%;
  height: 6%;
  background-color: ${dbColors.background};
  min-height: 3em;
`;

const LogoContainer = styled.div`
  position: absolute;
  height: 100%;
  margin-left: 2em;
  display: -webkit-flex;
  display: flex;
`;

class Topbar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MainTopBar id={"top-Bar"}>
        <LogoContainer id={"logo_container"}>
            {this.props.children}
          {/* <img style={{ height: "100%" }} src={this.props.logo} /> */}
        </LogoContainer>
        {this.props.buttons.reload && <PageReloadButton />}
        {this.props.buttons.export && <ExportButton />}
      </MainTopBar>
    );
  }
}

Topbar.defaultProps = {
  buttons: {
    export: true,
    reload: true,
  },
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(Topbar);
