import React from "react";

const RoutePopularityIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M124.63,7.48c1.29,0,2.56,0.34,3.67,0.98l95.47,55.12c2.27,1.31,3.67,3.74,3.67,6.36v110.24c0,2.62-1.41,5.05-3.67,6.36
	l-95.47,55.12c-1.11,0.64-2.38,0.98-3.67,0.98s-2.56-0.34-3.67-0.98l-95.47-55.12c-2.27-1.31-3.67-3.74-3.67-6.36V69.94
	c0-2.62,1.41-5.05,3.67-6.36l95.47-55.12C122.08,7.82,123.34,7.48,124.63,7.48 M124.63,0.13c-2.54,0-5.07,0.66-7.34,1.97
	L21.81,57.22c-4.54,2.62-7.34,7.47-7.34,12.72v110.24c0,5.25,2.8,10.1,7.34,12.72l95.47,55.12c2.27,1.31,4.81,1.97,7.34,1.97
	s5.07-0.66,7.34-1.97l95.47-55.12c4.54-2.62,7.34-7.47,7.34-12.72V69.94c0-5.25-2.8-10.1-7.34-12.72L131.98,2.1
	C129.7,0.79,127.17,0.13,124.63,0.13L124.63,0.13z"
    />
    <g>
      <circle fill={fill} cx="104.73" cy="137.7" r="11.79" />
      <path
        fill={fill}
        d="M154.9,139.21c-9.35,0-16.92,7.58-16.92,16.92c0,9.35,7.58,16.92,16.92,16.92c9.35,0,16.92-7.58,16.92-16.92
		C171.82,146.79,164.24,139.21,154.9,139.21z"
      />
      <path
        fill={fill}
        d="M181.09,64.51c-5.4,0-9.78,4.38-9.78,9.78c0,5.4,4.38,9.78,9.78,9.78c5.4,0,9.78-4.38,9.78-9.78
		C190.88,68.89,186.5,64.51,181.09,64.51z"
      />
      <circle fill={fill} cx="107.92" cy="177.02" r="8.6" />
      <circle fill={fill} cx="190.88" cy="118.33" r="13.36" />
      <circle fill={fill} cx="142.08" cy="103.19" r="22.78" />
      <ellipse
        fill={fill}
        transform="matrix(0.3827 -0.9239 0.9239 0.3827 -102.3661 166.4251)"
        cx="73.35"
        cy="159.81"
        rx="8.6"
        ry="8.6"
      />
      <circle fill={fill} cx="89.21" cy="95.19" r="11.79" />
      <circle fill={fill} cx="56.82" cy="123.41" r="11.79" />
    </g>
  </svg>
);

export default RoutePopularityIcon;
