import React from "react";

const SpeedIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
  margin = "",
  float = "left",
  clear = "none"
}) => (
  <svg
  width={width}
  style={style}
  height={width}
  viewBox={viewBox}
  style={style}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
<g>
  <path fill={fill} d="M124.63,7.35c1.29,0,2.56,0.34,3.68,0.99l95.58,55.18c2.27,1.31,3.68,3.75,3.68,6.37v110.36c0,2.62-1.41,5.06-3.68,6.37
    l-95.58,55.18c-1.12,0.64-2.39,0.99-3.68,0.99c-1.29,0-2.56-0.34-3.68-0.99l-95.58-55.18c-2.27-1.31-3.68-3.75-3.68-6.37V69.89
    c0-2.62,1.41-5.06,3.68-6.37l95.58-55.18C122.07,7.69,123.34,7.35,124.63,7.35 M124.63,0c-2.54,0-5.08,0.66-7.35,1.97L21.7,57.15
    c-4.55,2.63-7.35,7.48-7.35,12.73v110.36c0,5.25,2.8,10.11,7.35,12.73l95.58,55.18c2.27,1.31,4.81,1.97,7.35,1.97
    c2.54,0,5.08-0.66,7.35-1.97l95.58-55.18c4.55-2.63,7.35-7.48,7.35-12.73V69.89c0-5.25-2.8-10.11-7.35-12.73L131.98,1.97
    C129.71,0.66,127.17,0,124.63,0L124.63,0z"/>
</g>
<g>
  <g>
    <path fill={fill} d="M68.29,171.43c-1.23,0-2.44-0.62-3.13-1.75c-6.79-10.99-10.37-23.66-10.37-36.65c0-38.51,31.33-69.84,69.84-69.84
      c38.51,0,69.85,31.33,69.85,69.84c0,12.98-3.59,25.66-10.38,36.65c-1.07,1.73-3.33,2.26-5.06,1.2c-1.73-1.07-2.26-3.33-1.2-5.06
      c6.07-9.83,9.28-21.17,9.28-32.78c0-34.46-28.04-62.49-62.49-62.49c-34.46,0-62.49,28.03-62.49,62.49
      c0,11.62,3.21,22.96,9.28,32.78c1.07,1.73,0.53,3.99-1.2,5.06C69.62,171.25,68.95,171.43,68.29,171.43z"/>
  </g>
  <path fill={fill} d="M160.49,97.19c-1.44-1.44-3.76-1.44-5.2,0l-27.67,27.67c-0.93-0.34-1.94-0.54-2.99-0.54c-4.82,0-8.73,3.91-8.73,8.73
    c0,4.82,3.91,8.73,8.73,8.73c4.82,0,8.73-3.91,8.73-8.73c0-1.05-0.2-2.06-0.54-2.99l27.66-27.66
    C161.92,100.95,161.92,98.62,160.49,97.19z"/>
</g>
</svg>

);

export default SpeedIcon;
