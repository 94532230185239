import React from "react";
import { connect } from "react-redux";
import { toggleModal } from "kepler.gl/actions";
import ExportArrowIcon from "../icon/export-arrow";
import styled from "styled-components";

const ExportButtonDiv = styled.div`
  width: 25px;
  height: 25px;
  float: right;
  margin: 1em 1em 1em 0em;
  cursor: pointer;
`;

class ExportButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      //JSX code for component
      <ExportButtonDiv onClick={() => this.props.toggleModal("exportImage")}>
        <ExportArrowIcon />
      </ExportButtonDiv>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { toggleModal })(ExportButton);
