import React from "react";
import { dbColors } from "../constants/dbColors";

const ReloadArrowIcon = ({
  style = {
    float: "right",
    clear: "both",
  },
  fill = dbColors.highlight,
  width = "100%",
  className = "",
  viewBox = "0 0 64 64"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path fill={fill} d="M39.1,14.9c2.5,1,4.7,2.4,6.5,4.2c0.7,0.7,0.4,1.7-0.4,2l-3.9,1.7c-1.1,0.5-1,1.9,0.1,2.4l15.9,6c0.7,0.3,1.4-0.1,1.6-0.8
		l6-15.9c0.4-1.1-0.7-2.1-1.7-1.6l-3.9,1.7c-0.6,0.3-1.3,0.1-1.5-0.5c-3.5-4.9-8.3-8.9-14.4-11.1C27.9-2.8,10.6,4.8,4.1,19.8
		c-0.3,0.7,0.1,1.4,0.8,1.6l9.4,3.6c0.7,0.2,1.4,0,1.6-0.7C19.9,15.8,30,11.4,39.1,14.9z"/>
	<path fill={fill} d="M26,49.2c-2.5-1-4.7-2.4-6.5-4.2c-0.6-0.7-0.4-1.7,0.4-2l5.4-2.4c1.1-0.5,1-1.9-0.1-2.4L8.2,31.6c-0.7-0.3-1.4,0.1-1.6,0.8
		L0.1,49.4c-0.4,1.1,0.7,2.1,1.7,1.6l3.9-1.7c0.6-0.3,1.3-0.1,1.5,0.5c3.5,4.9,8.3,8.9,14.3,11.1c15.5,5.9,32.8-1.6,39.3-16.7
		c0.3-0.7-0.1-1.4-0.8-1.6L50.6,39c-0.7-0.2-1.4,0-1.6,0.7C45.3,48.4,35.2,52.6,26,49.2z"/>

  </svg>
);

export default ReloadArrowIcon;
