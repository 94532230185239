import React from "react";

const Co2Icon = ({
  style = {},
  fill = "#000000",
  width = "60px",
  height = "25px",
  className = "",
  viewBox = "0 0 60 30"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M30.87,13a3.39,3.39,0,0,1,1.38-1.84,10.81,10.81,0,0,1,1.66-.57l.25-.1a2,2,0,0,0,.63-.55C35.66,8.79,41.39,2.5,46,2.5H57V.26H46C40,.26,33.6,7.88,33.11,8.5a11.11,11.11,0,0,0-1.89.69,5.49,5.49,0,0,0-2.45,3.06l-2,6.08a3.2,3.2,0,0,0,0,2.26,5.05,5.05,0,0,0-.89-.58,5.78,5.78,0,0,0,0-4.75,5.48,5.48,0,0,0-3.5-2.85A6.21,6.21,0,0,0,14.42,4.5c-.9-1.57-3-2.54-5.49-2.38S3.71,3.81,3.29,6.77a4.35,4.35,0,0,0-2.13,1A3.51,3.51,0,0,0,0,10.4a4.08,4.08,0,0,0,3.31,3.89,6.1,6.1,0,0,0,3.37,5.94,7.4,7.4,0,0,0,5.64.89,6.91,6.91,0,0,0,3,4.3,6.19,6.19,0,0,0,4.76.52,5.22,5.22,0,0,0,4,2.22A3.83,3.83,0,0,0,25.33,28a4.57,4.57,0,0,0,2.87-4.48,4,4,0,0,0-.34-1.35,3.23,3.23,0,0,0,1.92.66h4.35c0,.3,0,.6.09.9H30.73v2.23h4.19a7.33,7.33,0,0,0,13.91-3.13H57V20.55H48.5a7.31,7.31,0,0,0-14,0H29.78a1,1,0,0,1-.84-.45,1.05,1.05,0,0,1-.08-1ZM24.59,25.84c-1,.35-2.12-.34-3.19-1.84l-.5-.7-.8.29a4.33,4.33,0,0,1-3.64-.09c-1.16-.69-2-2.17-2.28-4.31l-.36-2.33-1.57,1.76c-.63.7-2.61.74-4.45-.32-.91-.53-2.94-2-2.08-4.62l.48-1.47H4.66c-1.59,0-2.43-.91-2.43-1.81a1.3,1.3,0,0,1,.43-1,2.48,2.48,0,0,1,1.69-.52H5.5l0-1.15c-.1-3,3-3.37,3.61-3.41,1.6-.12,3.42.52,3.55,1.74l.17,1.57L14.22,7c2-1,4-.68,5.24.8a3.94,3.94,0,0,1,.27,4.8L18.6,14.16l1.93.18a3.77,3.77,0,0,1,3.27,1.89A3.88,3.88,0,0,1,23.31,20l-.89,1.35,1.58.36c1.23.27,1.92.92,2,1.87A2.31,2.31,0,0,1,24.59,25.84Zm16.89-8.33a5.13,5.13,0,1,1-5.13,5.13A5.13,5.13,0,0,1,41.48,17.51Z"
    />
  </svg>
);

export default Co2Icon;
