import React, { Fragment } from "react";
import styled from "styled-components";

import Topbar from "./svgTopBar";
import CyclingIrelandLayerUiContainer from "./cyclingIrelandLayerUiContainer";
import CyclingIrelandInsightsContainer from "./cyclingIrelandInsightsContainer";
import Logo from "../img/seesense-logo-cycling-ireland";
import ZoomButtons from "./zoom-buttons";
import ContainerColumn from "./dashboard-control-container-column"
import CyclistFilter from "../cyclingIreland/components/cycling-ireland-cyclist-filter";

const DashboardControlsContainer = styled.div`
  display: inline-flex;
  position: fixed;
  margin: 0;
  top: 6%;
  height: 94%;
  width: 100%;
  z-index: 2;
  pointer-events: none;
`;

const CyclingIrelandDashboardMainUserInterface = (props) => {
  return (
    <Fragment>
      <Topbar buttons={{ export: false, reload: true }}>
        <Logo />
      </Topbar>
      {props.children}
      <DashboardControlsContainer id="dashboard-controls_main-container">
        <CyclingIrelandLayerUiContainer />
        <ContainerColumn id={"cyclist-filter--column_container"}>
          <CyclistFilter />
        </ContainerColumn>
      </DashboardControlsContainer>
      <ZoomButtons />
      <CyclingIrelandInsightsContainer />
    </Fragment>
  );
};

export default CyclingIrelandDashboardMainUserInterface;
