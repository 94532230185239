import {
  PANEL_EXPAND_TOGGLE,
  INSIGHT_EXPAND_TOGGLE,
  UI_CONTAINER_TOGGLE,
  UPDATE_FILTERED_BIKE_ID_LIST,
  UPDATE_FILTER_INPUT_VALUE,
  UPDATE_AVAILABLE_DATES,
  SET_DATE_FILTER_ENVELOPE,
  AMPM_EXPAND_TOGGLE,
  AGE_EXPAND_TOGGLE,
  GENDER_EXPAND_TOGGLE,
  BIKETYPE_EXPAND_TOGGLE,
  DATE_PICKER_TOGGLE,
  UPDATE_FILE_LAST_MODIFIED,
} from "./action-constants";

// ACTIONS

export function panelExpandToggle(panelId) {
  return {
    type: PANEL_EXPAND_TOGGLE,
    panelId,
  };
}

export function insightExpandToggle(insightId) {
  return {
    type: INSIGHT_EXPAND_TOGGLE,
    insightId,
  };
}

export function uiContainerToggle(payload) {
  return {
    type: UI_CONTAINER_TOGGLE,
    payload,
  };
}

export function updateFilterBikeIdList(payload) {
  return {
    type: UPDATE_FILTERED_BIKE_ID_LIST,
    payload,
  };
}

export function updateFilterInputValue(payload) {
  return {
    type: UPDATE_FILTER_INPUT_VALUE,
    payload,
  };
}

export function updateAvailableDates(payload) {
  return {
    type: UPDATE_AVAILABLE_DATES,
    payload,
  };
}

export function setDateFilterEnvelope(payload) {
  return {
    type: SET_DATE_FILTER_ENVELOPE,
    payload,
  }
}

export function datePickerToggle() {
  return {
    type: DATE_PICKER_TOGGLE,
  };
}

export function updateFileLastModified(payload) {
  return {
    type: UPDATE_FILE_LAST_MODIFIED,
    payload
  }
}


export function ampmExpandToggle() {
  return {
    type: AMPM_EXPAND_TOGGLE,
  };
}

export function ageExpandToggle() {
  return {
    type: AGE_EXPAND_TOGGLE,
  };
}

export function genderExpandToggle() {
  return {
    type: GENDER_EXPAND_TOGGLE,
  };
}

export function bikeTypeExpandToggle() {
  return {
    type: BIKETYPE_EXPAND_TOGGLE,
  };
}
