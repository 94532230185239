import React from "react";
import { connect } from "react-redux";
import { uiContainerToggle } from "../constants/dashUI-actions";

import { dbColors } from "../constants/dbColors.js";

import PanelCrossIcon from "../icon/panel-cross";
import PanelArrowIcon from "../icon/panel-arrow";

class InsightContainerToggle extends React.Component {
  constructor(props) {
    super(props);
  }

  //helper functions and the like go here

  render(){
    //JSX goes in here
    return(
      <div
        id={"insightContainerToggle"}
        className={"insightContainerToggle"}
        onClick={()=>this.props.uiContainerToggle("insightContainer")}
        style={{
          width: "1.5em",
          height: "1.5em",
          backgroundColor: dbColors.background,
          marginBottom: "0.6em",
          borderRadius: "5px",
          boxShadow: "2px 2px 5px 0px #00000014",
          cursor: "pointer"
        }}
      >
      {this.props.dashboard.dbUiState.uiContainers.insightContainer.active?
        <PanelCrossIcon fill={dbColors.highlight} style={{margin: 0}} transform={"translate(6, 6)"}/>:
        <PanelArrowIcon fill={dbColors.highlight} style={{margin: 0}} transform={"rotate(180) translate(12, 12)"}/>
      }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state;
};
const dispatchToProps = dispatch => ({ dispatch });

export default connect(
  mapStateToProps,
  { uiContainerToggle }
)(InsightContainerToggle);
