import React from "react";

const SustransIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <path
        fill={fill}
        d="M125,7.35a7.36,7.36,0,0,1,3.67,1L224.2,63.48a7.37,7.37,0,0,1,3.67,6.37v110.3a7.37,7.37,0,0,1-3.67,6.37l-95.53,55.15a7.36,7.36,0,0,1-7.34,0L25.8,186.52a7.37,7.37,0,0,1-3.67-6.37V69.85a7.37,7.37,0,0,1,3.67-6.37L121.33,8.33a7.36,7.36,0,0,1,3.67-1M125,0a14.73,14.73,0,0,0-7.35,2L22.13,57.12a14.7,14.7,0,0,0-7.35,12.73v110.3a14.7,14.7,0,0,0,7.35,12.73L117.65,248a14.7,14.7,0,0,0,14.7,0l95.52-55.15a14.7,14.7,0,0,0,7.35-12.73V69.85a14.7,14.7,0,0,0-7.35-12.73L132.35,2A14.73,14.73,0,0,0,125,0Z"
        transform="translate(-14.78)"
      />
      <path
        fill={fill}
        d="M131.09,144.85c0,5.08,0,22.31,0,29.46a3.15,3.15,0,0,1-3.14,3.14H81.56a3.16,3.16,0,0,1-3.15-3.15V129.16a4.39,4.39,0,0,1,4.38-4.38l39.36-.54a3,3,0,0,0,2.65-1.63,69.91,69.91,0,0,0,4.13-10.89,1.3,1.3,0,0,0-1.26-1.63c-8.33,0-44.88,0-44.88,0a19.09,19.09,0,0,0-19.06,19.06v45.15a17.82,17.82,0,0,0,17.83,17.82h45.15a19.07,19.07,0,0,0,19-19l-.08-39.59a1.14,1.14,0,0,0-2-.74c-2.25,2.67-4.62,4.78-11.4,9.81A2.89,2.89,0,0,0,131.09,144.85Z"
        transform="translate(-14.78)"
      />
      <path
        fill={fill}
        d="M108.73,96.26l6.26,6.63a6.63,6.63,0,0,0,9.09.5l7.74-6.58a1.56,1.56,0,0,1,2.55,1.42c-5.59,33.71-21.61,51.58-27.66,57.28a.4.4,0,0,0,.45.66c10.55-5.47,47.42-26.62,52.38-57.25a1.54,1.54,0,0,1,2.61-.81l4.7,4.71a6.62,6.62,0,0,0,9,.36l8.12-7a6.61,6.61,0,0,0,.41-9.67L151.55,53.3a6.62,6.62,0,0,0-9.41,0L108.83,87.07A6.62,6.62,0,0,0,108.73,96.26Z"
        transform="translate(-14.78)"
      />
    </g>
  </svg>
);

export default SustransIcon;
