import React from "react";
import { dbColors } from "../constants/dbColors";

const ExportArrowIcon = ({
  style = {
    float: "right",
    clear: "both",
  },
  fill = dbColors.highlight,
  width = "100%",
  className = "",
  viewBox = "0 0 64 64"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path fill={fill} d="M49,35.8v11.3c0,1-0.8,1.8-1.8,1.8H16.8c-1,0-1.8-0.8-1.8-1.8V35.8c0-1-0.8-1.8-1.8-1.8H1.8c-1,0-1.8,0.8-1.8,1.8V49v13.2
    c0,1,0.8,1.8,1.8,1.8H15h34h13.2c1,0,1.8-0.8,1.8-1.8V49V35.8c0-1-0.8-1.8-1.8-1.8H50.8C49.8,34,49,34.8,49,35.8z"/>
  <path fill={fill} d="M26.3,40h11.3c1,0,1.8-0.8,1.8-1.8V23.8c0-1,0.8-1.8,1.8-1.8h8.2c1.6,0,2.5-2,1.3-3.2L33.3,1.3c-0.7-0.7-1.9-0.7-2.6,0
    L13.2,18.8C12,20,12.8,22,14.5,22h8.2c1,0,1.8,0.8,1.8,1.8v14.3C24.5,39.2,25.3,40,26.3,40z"/>
  </svg>
);

export default ExportArrowIcon;
