// Utility file containing functions for safely checking deeply nested information
// in the props object when it's not know if it's present yet

// USAGE:
// The get function itterates through each 'level' in the array of strings provided,
// using them as object keys. If a level evaluates as undefined the function will return null.
// If the desired value exist the function will return it.
"use strict";

export const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

// only applies to sustrans dashboard
export const getJourneysLayerVisibility = (a) => 
  get(["keplerGl", "map1", "visState", "layers", 1, "config", "isVisible"], a);

export const getBikeIdFilterStatus = (a) =>
  get(["keplerGl", "map1", "visState", "filters", 0, "value"], a);

// used when building the list of names of the bikes in the "Filter by Bike ID" UI
export const getBikeDatafile = (a) =>
  get(["keplerGl", "map1", "visState", "datasets", "msi9thb39", "allData"], a);