import React from "react";
import { connect } from "react-redux";
import Papa from "papaparse";
import styled from "styled-components";

import { cyclingIrelandInsightUiInfo } from "../cyclingIreland/constants/uiInfo";
import { cyclingIrelandFiles } from "Filepaths";
import InsightPanel from "./insight-panel";
import InsightContainerToggle from "./insight-container-toggle";

let cyclingIrelandInsightsData = "";

const InsightUiContainer = styled.div`
    position: fixed;
    z-index: 2;

    width: fit-content;
    height; fit-content;
    bottom: 0;
    left: 0;

    padding: 0em 0em 0.4em 1em;

    overflow: hidden;
`;

class CyclingIrelandInsightsContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    //gets and parses cyclingIreland insight data for the insight panels
    let cyclingIrelandInsightResponse = await fetch(
      cyclingIrelandFiles.cyclingIrelandInsights
    );
    let cyclingIrelandInsightsText = await cyclingIrelandInsightResponse.text();
    cyclingIrelandInsightsData = await Papa.parse(cyclingIrelandInsightsText, {
      header: true,
      dynamicTyping: true,
    }).data[0];
  }

  render() {
    return (
      //JSX for component
      <InsightUiContainer id={"insight_UI_container"}>
        <InsightContainerToggle />
        {this.props.dashboard.dbUiState.uiContainers.insightContainer
          .active && (
          <div id={"insights_inner-container"}>
            <InsightPanel
              info={cyclingIrelandInsightUiInfo.averageSpeed}
              data={cyclingIrelandInsightsData.average_speed}
              renderIcon={false}
              enableDropdown={false}
              width={"400px"}
            />
            <InsightPanel
              info={cyclingIrelandInsightUiInfo.averageJourneyDistance}
              data={cyclingIrelandInsightsData.average_journey_length}
              renderIcon={false}
              enableDropdown={false}
              displayInfoIcon={true}
              link={"https://seesense.cc/pages/more-information-cycling-ireland-dashboard"}
              width={"410px"}
            />
            <InsightPanel
              info={cyclingIrelandInsightUiInfo.co2Saved}
              data={cyclingIrelandInsightsData.co2_saved}
              renderIcon={false}
              enableDropdown={false}
              displayInfoIcon={true}
              link={"https://seesense.cc/pages/more-information-cycling-ireland-dashboard"}
              width={"370px"}
            />
          </div>
        )}
      </InsightUiContainer>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(CyclingIrelandInsightsContainer);
