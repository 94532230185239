export const dbUiInfoCyclingIreland = {
    routePopularityPanelInfo: {
      title: "Route Popularity",
      layerToControl: 0,
      panelId: "panelRP",
      layerDescription: "Heatmap showing popular cycle routes, darker coloured areas indicate where most cyclists are.",
      infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard#route_popularity_info"
    },
    speedPanelInfo: {
      title: "Speed",
      layerToControl: 1,
      panelId: "panelSpeed",
      layerDescription: "Heatmap showing the average speed of all cyclists passing through that area with green being fast and red slow.",
      infoUrl: "https://seesense.cc/pages/more-information-see-sense-data-insights-dashboard#speed_info"
    },
    dwellTimePanelInfo: {
      title: "Dwell Time",
      layerToControl: 2,
      panelId: "panelDwellTime",
      layerDescription: "3D layer where a darker colour shows higher number of cyclist waiting and height shows the average time waited.",
    infoUrl: "https://seesense.cc/pages/more-information-cycling-ireland-dashboard"
    }
  };
  
  export const cyclingIrelandInsightUiInfo = {
    averageSpeed: {
      title: "Average Cyclist Speed",
      number: 30,
      unit: "kmh",
      insightId: "averageSpeedInsight",
      InsightDescription: "Average speed of all Cycling Ireland members collecting See.Sense data."
    },
    averageJourneyDistance: {
      title: "Average Journey Distance",
      number: 45,
      unit: "km",
      insightId: "averageJourneyDistanceInsight",
      InsightDescription: "Average distance of journeys made by Cycling Ireland members who are collection See.Sense data."
    },
    co2Saved: {
      title: "Total CO2 Saved",
      number: 50,
      unit: "kg",
      insightId: "co2SavedInsight",
      InsightDescription: "Total CO2 emissions saved by Cycling Ireland members by cycling instead of driving."
    }
  };
  
  /*All data for the dashboard UI panel is stored here
  and exported as a single object to be distributed to the
  panel components in app.js*/
  