import React from "react";

const PerceptionIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
  margin = "",
  float = "left",
  clear = "none"
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M110.2,7.3c1.3,0,2.6,0.4,3.7,1l95.5,55.1c2.3,1.3,3.7,3.7,3.7,6.4v110.3c0,2.6-1.4,5.1-3.7,6.4l-95.5,55.1
  c-2.3,1.3-5.1,1.3-7.3,0L11,186.5c-2.3-1.3-3.7-3.7-3.7-6.4V69.8c0-2.6,1.4-5.1,3.7-6.4l95.5-55.2C107.7,7.7,108.9,7.3,110.2,7.3
   M110.2,0c-2.6,0-5.1,0.7-7.3,2L7.3,57.1C2.8,59.7,0,64.6,0,69.8v110.3c0,5.3,2.8,10.1,7.4,12.7l95.5,55.1c4.5,2.6,10.2,2.6,14.7,0
  l95.5-55.1c4.5-2.6,7.3-7.5,7.4-12.7V69.8c0-5.3-2.8-10.1-7.4-12.7L117.6,2C115.3,0.7,112.8,0,110.2,0z"
    />
    <path
      fill={fill}
      d="M56.1,100.4h29.6c2.1,0,3.7-1.7,3.7-3.7V77.9L82,85.2v7.7H59.9V70.7h16c0.8,0,1.5-0.3,2.1-0.9l6.7-6.6H56.1
    c-2,0-3.7,1.6-3.7,3.7v29.6C52.4,98.7,54.1,100.4,56.1,100.4z"
    />
    <path
      fill={fill}
      d="M85.8,110.2H56.2c-2.1,0-3.7,1.7-3.7,3.7v29.6c0,2.1,1.7,3.7,3.7,3.7h29.6c2.1,0,3.7-1.7,3.7-3.7v-29.6
    C89.5,111.8,87.9,110.2,85.8,110.2z M82.1,139.8H59.9v-22.2h22.2C82.1,117.6,82.1,139.8,82.1,139.8z"
    />
    <path
      fill={fill}
      d="M85.8,157.6H56.2c-2.1,0-3.7,1.7-3.7,3.7v29.6c0,2.1,1.7,3.7,3.7,3.7h29.6c2.1,0,3.7-1.7,3.7-3.7v-29.6
    C89.5,159.2,87.9,157.6,85.8,157.6z M82.1,187.2H59.9V165h22.2C82.1,165,82.1,187.2,82.1,187.2z"
    />
    <path
      fill={fill}
      d="M72.1,80l-3.3-3.9c-1.4-1.6-3.7-1.7-5.3-0.4c-1.6,1.3-1.7,3.7-0.4,5.3l8.5,9.8l17.9-17.6L102,61.1c1.5-1.4,1.5-3.8,0.1-5.2
    c-1.4-1.5-3.8-1.5-5.3-0.1l-8.6,8.4l-6.7,6.6L72.1,80z"
    />
    <path
      fill={fill}
      d="M94,81.8c0,2.1,1.7,3.7,3.7,3.7h66.6c2.1,0,3.7-1.7,3.7-3.7c0-2.1-1.7-3.7-3.7-3.7H97.7C95.7,78.1,94,79.8,94,81.8z"
    />
    <path
      fill={fill}
      d="M164.3,125H97.7c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7h66.6c2.1,0,3.7-1.7,3.7-3.7C168,126.7,166.4,125,164.3,125z
    "
    />
    <path
      fill={fill}
      d="M164.3,172.4H97.7c-2.1,0-3.7,1.7-3.7,3.7s1.7,3.7,3.7,3.7h66.6c2.1,0,3.7-1.7,3.7-3.7S166.4,172.4,164.3,172.4z"
    />
  </svg>
);

export default PerceptionIcon;
