import React, { Component } from "react";
import { connect } from "react-redux";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import KeplerGl from "kepler.gl";
import { createAction } from "redux-actions";
import CyclingIrelandDashboardMainUserInterface from "../components/cyclingIrelandDashboardMainUserInterface";
import { dbColors } from "../constants/dbColors";
import KeplerGlSchema from "kepler.gl/schemas"; //NEW!
import { addDataToMap, wrapTo, receiveMapConfig } from "kepler.gl/actions";
import { processCsvData } from "kepler.gl/processors";
import styled from "styled-components";
import { cyclingIrelandFiles } from "Filepaths";
//import Logo from "../img/SeeSense-Logo-Cycling-Ireland.png";

import cyclingIrelandConfig from "./configuration/config.json";

//the layer ID's from the config are stored in this object
const layerIds = {
  popularity: "exwolls39",
  speed: "58f4jx62d7",
  roughness: "f2euttsk",
  journeys: "l8igjoxh9",
  dwell: "l4adaufeb",
  combined: "exwolls39",
  cocarsBikes: "msi9thb39",
  cocarsJourneys: "js09vn7si",
};

const customTheme = {
  floatingBtnBgd: dbColors.highlight,
  floatingBtnBgdHover: "#4e74cc",
  floatingBtnColor: "#ffffff",
  floatingBtnActColor: "#ffffff",
  panelBackground: "#142E6B",
};

const MAPBOX_TOKEN =
 // "pk.eyJ1IjoiZXdhbmJlY2siLCJhIjoiY2pzZnk0Zmg1MDdlczN5bDVjbmt4MzFkbiJ9.FUXaom4P_JjmVBaHolfD0w"; // eslint-disable-line
  "pk.eyJ1IjoicGhpbGlwbWNhbGVlc2UiLCJhIjoiY2pyNWVvNmNxMGNzNTQybnphbGpxc2trdyJ9.cBnxbVGBepl6RtQHxWPDmg" // eslint-disable-line

// extra actions plugged into kepler.gl reducer (store.js)
const hideAndShowSidePanel = createAction("HIDE_AND_SHOW_SIDE_PANEL");

const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
const journeysLayerVisibility = (a) => 
  get(["keplerGl", "map1", "visState", "layers", 1, "config", "isVisible"], a);

class App extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const parsedConfig = KeplerGlSchema.parseSavedConfig(cyclingIrelandConfig);
    this.props.dispatch(receiveMapConfig(parsedConfig));

    const routePopResponse = await fetch(
      cyclingIrelandFiles.cyclingIrelandRoutePopAndSpeedFile
    );
    const routePopData = await routePopResponse.text();

    const routePopDataset = {
      data: processCsvData(routePopData),
      info: { id: layerIds.combined },
    };

    const dwellResponse = await fetch(
      cyclingIrelandFiles.cyclingIrelandDwellFile
    );
    const dwellData = await dwellResponse.text();

    const dwellDataset = {
      data: processCsvData(dwellData),
      info: { id: layerIds.dwell },
    };

    this.props.dispatch(
      wrapTo(
        "map1",
        addDataToMap({
          datasets: [
            routePopDataset,
            dwellDataset
          ],
          options: {
            centerMap: true,
          },
        })
      )
    );
  }

  render() {
    return (
      <div
        className={"wholePage"}
        style={{ position: "absolute", width: "100%", height: "100%" }}
      >
        <CyclingIrelandDashboardMainUserInterface>
          <div
            style={{
              top: "6%",
              height: "94%",
              width: "100%",
            }}
          >
            <AutoSizer
              style={{
                position: "absolute",
                float: "left",
                clear: "all",
                zIndex: 1,
              }}
            >
              {({ height, width }) => (
                <KeplerGl
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  id="map1"
                  width={width}
                  height={height}
                  theme={customTheme}
                />
              )}
            </AutoSizer>
          </div>
        </CyclingIrelandDashboardMainUserInterface>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(App);
