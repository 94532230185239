import React from "react";

const RoadSurfaceIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
}) => (
  <svg
  width={width}
  style={style}
  height={width}
  viewBox={viewBox}
  style={style}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
<g>
	<path fill={fill} d="M124.63,7.35c1.29,0,2.56,0.34,3.68,0.99l95.58,55.18c2.27,1.31,3.68,3.75,3.68,6.37v110.36c0,2.62-1.41,5.06-3.68,6.37
		l-95.58,55.18c-1.12,0.64-2.39,0.99-3.68,0.99c-1.29,0-2.56-0.34-3.68-0.99l-95.58-55.18c-2.27-1.31-3.68-3.75-3.68-6.37V69.89
		c0-2.62,1.41-5.06,3.68-6.37l95.58-55.18C122.07,7.69,123.34,7.35,124.63,7.35 M124.63,0c-2.54,0-5.08,0.66-7.35,1.97L21.7,57.15
		c-4.55,2.63-7.35,7.48-7.35,12.73v110.36c0,5.25,2.8,10.11,7.35,12.73l95.58,55.18c2.27,1.31,4.81,1.97,7.35,1.97
		s5.08-0.66,7.35-1.97l95.58-55.18c4.55-2.63,7.35-7.48,7.35-12.73V69.89c0-5.25-2.8-10.11-7.35-12.73L131.98,1.97
		C129.71,0.66,127.17,0,124.63,0L124.63,0z"/>
</g>
<g>
	<g id="XMLID_99_">
		<path fill={fill} d="M105.58,84.23l-19.05-14.7l-19.04,14.7L47.32,68.66c-0.8-0.62-0.95-1.77-0.33-2.58c0.62-0.8,1.77-0.95,2.58-0.33
			l17.92,13.84l19.04-14.7l19.05,14.7l19.04-14.7l19.05,14.7l19.05-14.7l19.05,14.7l17.93-13.84c0.8-0.62,1.96-0.47,2.58,0.33
			c0.62,0.8,0.47,1.96-0.33,2.58l-20.17,15.57l-19.05-14.7l-19.05,14.7l-19.05-14.7L105.58,84.23z"/>
	</g>
	<g id="XMLID_97_">
		<path fill={fill} d="M105.58,108L86.53,93.29L67.49,108L46.2,91.56c-1.61-1.24-1.9-3.55-0.66-5.16c1.24-1.61,3.55-1.9,5.16-0.66l16.8,12.97
			L86.53,84l19.05,14.7L124.62,84l19.05,14.7l19.05-14.7l19.05,14.7l16.81-12.97c1.61-1.24,3.92-0.94,5.16,0.66s0.94,3.92-0.66,5.16
			L181.77,108l-19.05-14.7L143.67,108l-19.05-14.7L105.58,108z"/>
	</g>
	<g id="XMLID_95_">
		<path fill={fill} d="M181.77,130.8c-5.76,0-8.58-4.35-11.07-8.19c-2.26-3.49-4.22-6.51-7.98-6.51s-5.72,3.02-7.98,6.51
			c-2.49,3.84-5.31,8.19-11.07,8.19s-8.58-4.35-11.07-8.19c-2.26-3.49-4.22-6.51-7.98-6.51c-3.76,0-5.72,3.02-7.98,6.51
			c-2.49,3.84-5.3,8.19-11.06,8.19c-5.76,0-8.58-4.35-11.07-8.19c-2.26-3.49-4.22-6.51-7.98-6.51c-3.76,0-5.72,3.02-7.98,6.51
			c-2.49,3.84-5.3,8.19-11.07,8.19c-5.76,0-8.58-4.35-11.06-8.19c-2.26-3.49-4.22-6.51-7.98-6.51c-1.02,0-1.84-0.82-1.84-1.84
			s0.82-1.84,1.84-1.84c5.76,0,8.58,4.35,11.07,8.19c2.26,3.49,4.22,6.51,7.98,6.51c3.76,0,5.72-3.02,7.98-6.51
			c2.49-3.84,5.3-8.19,11.07-8.19c5.76,0,8.58,4.35,11.07,8.19c2.26,3.49,4.22,6.51,7.98,6.51c3.76,0,5.72-3.02,7.98-6.51
			c2.49-3.84,5.3-8.19,11.06-8.19c5.76,0,8.58,4.35,11.07,8.19c2.26,3.49,4.22,6.51,7.98,6.51s5.72-3.02,7.98-6.51
			c2.49-3.84,5.31-8.19,11.07-8.19s8.58,4.35,11.07,8.19c2.26,3.5,4.22,6.51,7.98,6.51s5.72-3.02,7.98-6.51
			c2.49-3.84,5.31-8.19,11.07-8.19c1.01,0,1.84,0.82,1.84,1.84s-0.82,1.84-1.84,1.84c-3.76,0-5.72,3.02-7.98,6.51
			C190.35,126.45,187.53,130.8,181.77,130.8z"/>
	</g>
	<g id="XMLID_91_">
		<path fill={fill} d="M181.77,152.55c-6.76,0-10.01-5.01-12.61-9.03c-2.56-3.95-3.94-5.67-6.44-5.67s-3.88,1.73-6.44,5.67
			c-2.61,4.02-5.85,9.03-12.61,9.03s-10-5.01-12.61-9.03c-2.56-3.95-3.94-5.67-6.44-5.67c-2.5,0-3.88,1.73-6.44,5.67
			c-2.6,4.02-5.85,9.03-12.61,9.03c-6.76,0-10-5.01-12.61-9.03c-2.56-3.95-3.94-5.67-6.44-5.67c-2.5,0-3.88,1.73-6.44,5.67
			c-2.61,4.02-5.85,9.03-12.61,9.03c-6.76,0-10-5.01-12.61-9.03c-2.56-3.95-3.94-5.67-6.44-5.67c-2.03,0-3.68-1.65-3.68-3.68
			s1.65-3.68,3.68-3.68c6.76,0,10,5.01,12.61,9.03c2.55,3.94,3.94,5.67,6.44,5.67c2.5,0,3.88-1.73,6.44-5.67
			c2.61-4.02,5.85-9.03,12.61-9.03c6.76,0,10,5.01,12.61,9.03c2.56,3.95,3.94,5.67,6.44,5.67c2.5,0,3.88-1.73,6.44-5.67
			c2.6-4.02,5.85-9.03,12.61-9.03c6.76,0,10,5.01,12.61,9.03c2.56,3.95,3.94,5.67,6.44,5.67s3.88-1.73,6.44-5.67
			c2.61-4.02,5.85-9.03,12.61-9.03c6.76,0,10,5.01,12.61,9.03c2.56,3.95,3.94,5.67,6.44,5.67s3.88-1.73,6.44-5.67
			c2.61-4.02,5.85-9.03,12.61-9.03c2.03,0,3.68,1.65,3.68,3.68s-1.65,3.68-3.68,3.68c-2.5,0-3.88,1.73-6.44,5.67
			C191.78,147.54,188.53,152.55,181.77,152.55z"/>
	</g>
	<g id="XMLID_90_">
		<path fill={fill} d="M200.82,179.25H48.44c-2.03,0-3.68-1.65-3.68-3.68s1.65-3.68,3.68-3.68h152.38c2.03,0,3.68,1.65,3.68,3.68
			S202.85,179.25,200.82,179.25z"/>
	</g>
	<g id="XMLID_81_">
		<g id="XMLID_89_">
			<path fill={fill} d="M200.82,164.83H48.44c-1.02,0-1.84-0.82-1.84-1.84s0.82-1.84,1.84-1.84h152.38c1.01,0,1.84,0.82,1.84,1.84
				S201.84,164.83,200.82,164.83z"/>
		</g>
	</g>
</g>
</svg>

);

export default RoadSurfaceIcon;
