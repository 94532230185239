import {
    PANEL_EXPAND_TOGGLE,
    INSIGHT_EXPAND_TOGGLE,
    UI_CONTAINER_TOGGLE
  } from "../action-constants";

// INITIAL_STATE

const initialState = {
  dbUiState: {
    layerPanels: {
      panelRP: {
        active: false,
      },
      panelSpeed: {
        active: false,
      },
      panelDwellTime: {
        active: false,
      }
    },
    insightPanels: {
      averageSpeedInsight: {
        active: false,
      },
      averageJourneyDistanceInsight: {
        active: false,
      },
      co2SavedInsight: {
        active: false,
      },
    },
    uiContainers: {
      insightContainer: {
        active: true,
      },
      cyclistFilterUi: {
        active: false,
      },
      ampmUi: {
        active: false,
      }
    }
  }
};

// The reducer takes the action and uses the TYPE for routing with a switch statement
// and the payload for actioning
// Object.assign() is used so as not to mutate the original state and a new function
// call is needed for every level of the dbUiState object

function dbReducer(state = initialState, action) {
  switch (action.type) {
    case PANEL_EXPAND_TOGGLE:
      return Object.assign({}, state, {
        dbUiState: Object.assign({}, state.dbUiState, {
          layerPanels: Object.assign({}, state.dbUiState.layerPanels, {
            [action.panelId]: Object.assign(
              {},
              state.dbUiState.layerPanels[action.panelId],
              {
                active: !state.dbUiState.layerPanels[action.panelId].active,
              }
            ),
          }),
        }),
      });
    case INSIGHT_EXPAND_TOGGLE:
      return Object.assign({}, state, {
        dbUiState: Object.assign({}, state.dbUiState, {
          insightPanels: Object.assign({}, state.dbUiState.insightPanels, {
            [action.insightId]: Object.assign(
              {},
              state.dbUiState.insightPanels[action.insightId],
              {
                active: !state.dbUiState.insightPanels[action.insightId].active,
              }
            ),
          }),
        }),
      });
    case UI_CONTAINER_TOGGLE:
      return Object.assign({}, state, {
        dbUiState: Object.assign({}, state.dbUiState, {
          uiContainers: Object.assign({}, state.dbUiState.uiContainers, {
            [action.payload]: Object.assign(
              {},
              state.dbUiState.uiContainers[action.payload],
              { active: !state.dbUiState.uiContainers[action.payload].active }
            ),
          }),
        }),
      });
    default:
      return state;
  }
}

export default dbReducer;
