export const londonDataFile = "http://london.seesense.cc/data/london.csv";

export const greaterDublinAreaDatafile =
  "http://dublin.seesense.cc/data/greater_dublin_area.csv";

export const greaterDublinPerceptionDatafile =
  "http://dublin.seesense.cc/data/greater_dublin_perception_data.csv";

export const sustransFiles = {
  sustransEdinburghDataFile: "http://sustrans.seesense.cc/data/sustrans_ss_data.csv",
  sustransBikes: "http://sustrans.seesense.cc/data/sustrans_bike_data.csv",
  sustransInsights: "http://sustrans.seesense.cc/data/sustrans_insight_data.csv",
  sustransJourneys: "http://sustrans.seesense.cc/data/sustrans_journey_data.csv",
};

export const cocarsFiles = {
  cocarsDataFile: "http://cocars.seesense.cc/data/cocars_ss_data.csv",
  cocarsBikes: "http://cocars.seesense.cc/data/cocars_bike_data.csv",
  cocarsInsights: "http://cocars.seesense.cc/data/cocars_insight_data.csv",
  cocarsJourneys: "http://cocars.seesense.cc/data/cocars_journey_data.csv",
};

export const cyclingIrelandFiles = {
  cyclingIrelandRoutePopAndSpeedFile: "https://cyclingireland.seesense.cc/data/cyclingIreland_route_pop_and_speed_data.csv",
  cyclingIrelandDwellFile: "https://cyclingireland.seesense.cc/data/cyclingIreland_dwell_data.csv",
  cyclingIrelandInsights: "https://cyclingireland.seesense.cc/data/cyclingIreland_insights_data.csv"
}

export const aveiroFiles = {
  aveiroCombinedDataFile: "http://aveiro.seesense.cc/data/aveiro_combined_data.csv",
  aveiroOriginDestinationFile: "http://aveiro.seesense.cc/data/aveiro_origin_destination_data.csv",
  aveiroPerceptionFile: "http://aveiro.seesense.cc/data/aveiro_perception_data.csv"
}

export const fleetdemoFiles = {
  fleetdemoDataFile: "http://fleetdemo.seesense.cc/data/fleetdemo_ss_data.csv",
  fleetdemoBikes: "http://fleetdemo.seesense.cc/data/fleetdemo_bike_data.csv",
  fleetdemoInsights: "http://fleetdemo.seesense.cc/data/fleetdemo_insight_data.csv",
  fleetdemoJourneys: "http://fleetdemo.seesense.cc/data/fleetdemo_journey_data.csv",
};

export const denverFiles = {
  denverCombinedDataFile: "http://denver.seesense.cc/data/denver_combined_data.csv",
  denverOriginDestinationFile: "http://aveiro.seesense.cc/data/denver_origin_destination_data.csv",
  denverPerceptionFile: "http://aveiro.seesense.cc/data/denver_perception_data.csv"
}