import React from "react";

const JourneysIcon2 = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
  margin = "",
  float = "left",
  clear = "none"
}) => (
  <svg
  width={width}
  style={style}
  height={width}
  viewBox={viewBox}
  style={style}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <path
    fill={fill}
    d="M125,7.35c1.29,0,2.56,0.34,3.67,0.98l95.53,55.15c2.27,1.31,3.67,3.75,3.67,6.36v110.3c0,2.62-1.41,5.06-3.67,6.36
      l-95.53,55.15c-1.12,0.64-2.39,0.98-3.67,0.98s-2.56-0.34-3.67-0.98L25.8,186.52c-2.27-1.31-3.67-3.75-3.67-6.36V69.85
      c0-2.62,1.41-5.06,3.67-6.36l95.53-55.15C122.44,7.69,123.71,7.35,125,7.35 M125,0c-2.54,0-5.07,0.66-7.35,1.97L22.13,57.12
      c-4.55,2.63-7.35,7.48-7.35,12.73v110.3c0,5.25,2.8,10.1,7.35,12.73l95.53,55.15c2.27,1.31,4.81,1.97,7.35,1.97
      s5.07-0.66,7.35-1.97l95.53-55.15c4.55-2.63,7.35-7.48,7.35-12.73V69.85c0-5.25-2.8-10.1-7.35-12.73L132.35,1.97
      C130.07,0.66,127.54,0,125,0L125,0z"
  />
  <path
    fill={fill}
    d="M58.99,170.22c2.57,0,4.67,2.09,4.67,4.67c0,2.57-2.09,4.67-4.67,4.67s-4.67-2.09-4.67-4.67
          C54.32,172.31,56.42,170.22,58.99,170.22 M58.99,162.87c-6.64,0-12.02,5.38-12.02,12.02c0,6.64,5.38,12.02,12.02,12.02
          s12.02-5.38,12.02-12.02C71.01,168.25,65.63,162.87,58.99,162.87L58.99,162.87z"
  />

  <circle fill={fill} cx="166.76" cy="158.15" r="12.02" />

  <circle fill={fill} cx="89.07" cy="112.91" r="12.02" />

  <path
    fill={fill}
    d="M182.36,61.98c2.57,0,4.67,2.09,4.67,4.67s-2.09,4.67-4.67,4.67c-2.57,0-4.67-2.09-4.67-4.67S179.79,61.98,182.36,61.98
           M182.36,54.64c-6.64,0-12.02,5.38-12.02,12.02s5.38,12.02,12.02,12.02c6.64,0,12.02-5.38,12.02-12.02S189,54.64,182.36,54.64
          L182.36,54.64z"
  />

  <path
    fill={fill}
    d="M76.66,175.11c-1.33,0-2.5-0.96-2.72-2.31c-0.24-1.5,0.77-2.92,2.28-3.16l72.95-11.88c1.5-0.25,2.92,0.78,3.16,2.28
          s-0.78,2.92-2.28,3.16L77.1,175.07C76.95,175.1,76.8,175.11,76.66,175.11z"
  />

  <path
    fill={fill}
    d="M151.67,150.19c-0.47,0-0.96-0.12-1.39-0.38l-46.6-27.42c-1.31-0.77-1.75-2.46-0.98-3.77c0.77-1.31,2.46-1.75,3.77-0.98
          l46.6,27.42c1.31,0.77,1.75,2.46,0.98,3.77C153.53,149.7,152.61,150.19,151.67,150.19z"
  />

  <path
    fill={fill}
    d="M104.14,105.14c-1.04,0-2.04-0.59-2.5-1.6c-0.64-1.38-0.03-3.02,1.35-3.66l61.22-28.24c1.38-0.64,3.02-0.03,3.66,1.35
          c0.64,1.38,0.03,3.02-1.35,3.66l-61.22,28.24C104.92,105.06,104.53,105.14,104.14,105.14z"
  />
</svg>

);

export default JourneysIcon2;
