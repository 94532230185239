import React from "react";

const AvgSpeedIcon = ({
  style = {},
  fill = "#000000",
  width = "60px",
  height = "25px",
  className = "",
  viewBox = "0 0 60 30"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
  <path fill={fill} d="M24.8,0C11.7,0,1,10.7,1,23.8c0,1.8,0.2,3.5,0.6,5.3c0.2,0.7,0.8,1.1,1.5,1c0.7-0.1,1.1-0.8,1-1.5
    c-0.3-1.6-0.5-3.1-0.5-4.7C3.5,12,13,2.5,24.8,2.5S46,12,46,23.8c0,1.6-0.2,3.2-0.5,4.7c-0.2,0.7,0.3,1.3,0.9,1.5
    c0.1,0,0.2,0,0.3,0c0.6,0,1.1-0.4,1.2-1c0.4-1.7,0.6-3.5,0.6-5.3C48.5,10.7,37.9,0,24.8,0z"/>
  <path fill={fill} d="M35.2,11.6L25.8,21c-0.3-0.1-0.7-0.2-1-0.2c-1.6,0-3,1.3-3,3c0,1.6,1.3,3,3,3c1.6,0,3-1.3,3-3c0-0.4-0.1-0.7-0.2-1
    l9.4-9.4c0.5-0.5,0.5-1.3,0-1.8C36.5,11.1,35.7,11.1,35.2,11.6z"/>
  </svg>
);

export default AvgSpeedIcon;
