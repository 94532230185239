import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { dbColors } from "../constants/dbColors";
import { updateMap } from "kepler.gl/actions";

const get = (p, o) => p.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);
const getZoomLevel = (a) => get(["keplerGl", "map1", "mapState", "zoom"], a);

const ZoomButtonContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 1em;
    bottom 2em;
`;

const ZoomButtonBase = styled.button`
  display: block;
  width: 1.8em;
  height: 1.8em;
  color: ${dbColors.background};
  background-color: ${dbColors.highlight};
  :hover {
    background-color: ${dbColors.highlight_hover};
  }
  cursor: pointer;
  border: none;
  font-size: large;
  font-family: inherit;
  font-weight: 600;
  outline: none;
`;

const ZoomInButton = styled(ZoomButtonBase)`
  border-radius: 7px 7px 0px 0px;
  border-bottom: solid;
  border-width: 1px;
`;

const ZoomOutButton = styled(ZoomButtonBase)`
  border-radius: 0px 0px 7px 7px;
`;

class ZoomButtons extends React.Component {
  constructor(props) {
    super(props);
  }

  zoom = (a) => {
    let z = getZoomLevel(this.props);
    if ((z+a) < 22 && (z+a) > 0) {
      this.props.updateMap({ zoom: z + a });
    }
  };

  render() {
    return (
      <ZoomButtonContainer id="zoom-buttons_container">
        <ZoomInButton id="zoom-in_button" onClick={() => this.zoom(1)}>+</ZoomInButton>
        <ZoomOutButton id="zoom-out_button" onClick={() => this.zoom(-1)}>-</ZoomOutButton>
      </ZoomButtonContainer>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps, {
  updateMap,
})(ZoomButtons);
