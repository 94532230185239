import React from "react";
import {
  RoutePopularityIcon,
  SpeedIcon,
  RoadSurfaceIcon,
  JourneysIcon2,
  DwellTimeIcon,
  BrakingIcon,
  SwervingIcon,
  PerceptionIcon,
  SustransIcon,
  JourneysGridIcon
} from "../constants/iconIndex.js";
import { dbColors } from "../constants/dbColors.js";

//COLOR DEFINITIONS
const background_LayerOff = dbColors.background;
const fill_LayerOff = dbColors.highlight;
const background_LayerOn = dbColors.highlight;
const fill_LayerOn = dbColors.background;

let iconHolder = <RoutePopularityIcon/>

const LayerIcon = ({title, fill}) => {
  if (fill === fill_LayerOn) {
    // switch statement for white icon
    switch (title) {
      case "Route Popularity":
        iconHolder = <RoutePopularityIcon fill={fill} />;
        break;
      case "Speed":
        iconHolder = <SpeedIcon fill={fill} />;
        break;
      case "Road Surface":
        iconHolder = <RoadSurfaceIcon fill={fill} />;
        break;
      case "Dwell Time":
        iconHolder = <DwellTimeIcon fill={fill} />;
        break;
      case "Swerving":
        iconHolder = <SwervingIcon fill={fill} />;
        break;
      case "Braking":
        iconHolder = <BrakingIcon fill={fill} />;
        break;
      case "Journeys":
        iconHolder = <JourneysGridIcon fill={fill} />;
        break
      case "Origin-Destination":
        iconHolder = <JourneysIcon2 fill={fill} />;
        break;
      case "Perception":
        iconHolder = <PerceptionIcon fill={fill} />;
        break;
      case "Bikes":
        iconHolder = <SustransIcon fill={fill} />;
        break;
      default:
        iconHolder = <RoutePopularityIcon fill="#ff0000" />;
    }
  } else if (fill === fill_LayerOff) {
    // switch statement for darkblue icons
    switch (title) {
      case "Route Popularity":
        iconHolder = <RoutePopularityIcon fill={fill} />;
        break;
      case "Speed":
        iconHolder = <SpeedIcon fill={fill} />;
        break;
      case "Road Surface":
        iconHolder = <RoadSurfaceIcon fill={fill} />;
        break;
      case "Dwell Time":
        iconHolder = <DwellTimeIcon fill={fill} />;
        break;
      case "Swerving":
        iconHolder = <SwervingIcon fill={fill} />;
        break;
      case "Braking":
        iconHolder = <BrakingIcon fill={fill} />;
        break;
      case "Journeys":
        iconHolder = <JourneysGridIcon fill={fill} />;
        break
      case "Origin-Destination":
        iconHolder = <JourneysIcon2 fill={fill} />;
        break;
      case "Perception":
        iconHolder = <PerceptionIcon fill={fill} />;
        break;
      case "Bikes":
        iconHolder = <SustransIcon fill={fill} />;
        break;
      default:
        iconHolder = <RoutePopularityIcon fill="#ff0000" />;
    }
  } else {
    iconHolder = <p>icon undefined</p>;
  }
  return iconHolder;
};

export default LayerIcon;
