import React from "react";

const SwervingIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
}) => (
  <svg
  width={width}
  style={style}
  height={width}
  viewBox={viewBox}
  style={style}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
<g>
	<path fill={fill} d="M124.63,7.26c1.29,0,2.56,0.34,3.68,0.99l95.65,55.22c2.27,1.31,3.68,3.75,3.68,6.37v110.45c0,2.62-1.41,5.06-3.68,6.37
		l-95.65,55.22c-1.12,0.64-2.39,0.99-3.68,0.99c-1.29,0-2.56-0.34-3.68-0.99L25.3,186.66c-2.27-1.31-3.68-3.75-3.68-6.37V69.84
		c0-2.62,1.41-5.06,3.68-6.37l95.65-55.22C122.07,7.6,123.34,7.26,124.63,7.26 M124.63-0.1c-2.54,0-5.08,0.66-7.36,1.97L21.62,57.1
		c-4.55,2.63-7.36,7.49-7.36,12.74v110.45c0,5.26,2.8,10.12,7.36,12.74l95.65,55.22c2.28,1.31,4.82,1.97,7.36,1.97
		s5.08-0.66,7.36-1.97l95.65-55.22c4.55-2.63,7.36-7.49,7.36-12.74V69.84c0-5.26-2.8-10.12-7.36-12.74L131.99,1.87
		C129.71,0.56,127.17-0.1,124.63-0.1L124.63-0.1z"/>
</g>
<g>
	<g>
		<path fill={fill} d="M72.82,176.55c-0.42,0-0.84-0.07-1.26-0.22c-1.91-0.7-2.89-2.81-2.2-4.71c4.26-11.7,14.49-14.56,23.52-17.09
			c8.38-2.35,15.62-4.38,18.59-12.53c2.97-8.15-1.28-14.36-6.19-21.54c-5.29-7.74-11.28-16.51-7.03-28.21
			c4.26-11.69,14.49-14.56,23.51-17.09c8.38-2.35,15.62-4.38,18.59-12.52c0.7-1.91,2.8-2.89,4.72-2.2c1.91,0.69,2.89,2.81,2.2,4.72
			c-4.26,11.69-14.49,14.56-23.51,17.09c-8.38,2.35-15.62,4.38-18.59,12.52c-2.97,8.15,1.28,14.35,6.19,21.54
			c5.29,7.74,11.28,16.51,7.03,28.21c-4.26,11.7-14.49,14.56-23.51,17.09c-8.38,2.35-15.62,4.38-18.59,12.53
			C75.73,175.62,74.32,176.55,72.82,176.55z"/>
	</g>
	<g>
		<path fill={fill} d="M105.46,189.93c-0.42,0-0.84-0.07-1.26-0.22c-1.91-0.7-2.89-2.81-2.2-4.71c4.26-11.7,14.49-14.56,23.52-17.09
			c8.38-2.35,15.62-4.38,18.59-12.53c2.96-8.15-1.28-14.35-6.19-21.54c-5.29-7.74-11.28-16.51-7.03-28.21
			c4.26-11.69,14.49-14.56,23.51-17.09c8.38-2.35,15.62-4.38,18.58-12.52c0.7-1.91,2.8-2.89,4.72-2.2c1.91,0.69,2.89,2.81,2.2,4.72
			c-4.26,11.69-14.49,14.56-23.51,17.09c-8.38,2.35-15.62,4.38-18.58,12.52c-2.97,8.15,1.28,14.35,6.19,21.54
			c5.29,7.74,11.28,16.51,7.03,28.21s-14.49,14.56-23.51,17.09c-8.38,2.35-15.62,4.38-18.59,12.53
			C108.37,189.01,106.96,189.93,105.46,189.93z"/>
	</g>
</g>
</svg>

);

export default SwervingIcon;
