import React from "react";
import { dbColors } from "../constants/dbColors";
import styled from "styled-components";

const LogoSvg  = styled.svg`
    height: 90%;
    margin: auto;
    display: block;
`

const Logo = ({
  fill = dbColors.highlight,
  viewBox = "0 0 646 50"
}) => (
  <LogoSvg
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g fill={fill}>
	<polygon points="71.4,38.9 71.4,44.6 66.4,44.6 66.4,38.9 71.4,38.9 71.4,38.9 	"/>
	<path d="M9.1,33.7C9.1,23,0,27.1,0,15.3V12c0-4,1.7-6.9,7.1-6.9S14.3,8,14.3,12v4.7H9.1V12c0-1.3-0.4-2.3-2-2.3c-1.5,0-2,0.9-2,2.3
		v3.2c0,9.2,9.1,5.1,9.1,18.4v4.5c0,4-1.7,6.9-7.1,6.9c-5.4,0-7.1-2.9-7.1-6.9v-5.3h5.2V38c0,1.3,0.5,2.3,2,2.3c1.5,0,2-1,2-2.3
		V33.7L9.1,33.7L9.1,33.7L9.1,33.7z"/>
	<polygon points="28.9,39.5 35.5,39.5 35.5,44.4 23.7,44.4 23.7,5.6 35.5,5.6 35.5,10.5 28.9,10.5 28.9,22.4 34.7,22.4 34.7,27.2 
		28.9,27.2 28.9,39.5 28.9,39.5 	"/>
	<polygon points="50.1,39.5 56.8,39.5 56.8,44.4 45,44.4 45,5.6 56.8,5.6 56.8,10.5 50.1,10.5 50.1,22.4 55.9,22.4 55.9,27.2 
		50.1,27.2 50.1,39.5 50.1,39.5 	"/>
	<path d="M89.2,33.7c0-10.7-9.1-6.7-9.1-18.3V12c0-4,1.7-6.9,7.1-6.9c5.4,0,7.1,2.9,7.1,6.9v4.7h-5.2V12c0-1.3-0.4-2.3-2-2.3
		c-1.5,0-2,0.9-2,2.3v3.2c0,9.2,9.1,5.1,9.1,18.4v4.5c0,4-1.7,6.9-7.1,6.9c-5.4,0-7.1-2.9-7.1-6.9v-5.3h5.2V38c0,1.3,0.5,2.3,2,2.3
		c1.5,0,2-1,2-2.3V33.7L89.2,33.7L89.2,33.7L89.2,33.7z"/>
	<polygon points="108.9,39.5 115.6,39.5 115.6,44.4 103.8,44.4 103.8,5.6 115.6,5.6 115.6,10.5 108.9,10.5 108.9,22.4 114.7,22.4 
		114.7,27.2 108.9,27.2 108.9,39.5 108.9,39.5 	"/>
	<path d="M136.4,32.4c-0.3-4.7-0.4-9.9-0.4-14.5V5.6h4.7v38.8h-4.9l-6.5-26.8c0.3,4.5,0.4,9.6,0.4,14.5v12.3H125V5.6h4.9L136.4,32.4
		L136.4,32.4L136.4,32.4L136.4,32.4z"/>
	<path d="M159.2,33.7c0-10.7-9.1-6.7-9.1-18.3V12c0-4,1.7-6.9,7.1-6.9c5.4,0,7.1,2.9,7.1,6.9v4.7h-5.2V12c0-1.3-0.4-2.3-2-2.3
		c-1.5,0-2,0.9-2,2.3v3.2c0,9.2,9.1,5.1,9.1,18.4v4.5c0,4-1.7,6.9-7.1,6.9s-7.1-2.9-7.1-6.9v-5.3h5.2V38c0,1.3,0.5,2.3,2,2.3
		c1.5,0,2-1,2-2.3V33.7L159.2,33.7L159.2,33.7L159.2,33.7z"/>
	<polygon points="178.9,39.5 185.6,39.5 185.6,44.4 173.8,44.4 173.8,5.6 185.6,5.6 185.6,10.5 178.9,10.5 178.9,22.4 184.7,22.4 
		184.7,27.2 178.9,27.2 178.9,39.5 178.9,39.5 	"/>
	<path d="M197.2,5.6c0.8,0,1.5,0.2,2.2,0.6c0.7,0.4,1.3,0.9,1.7,1.7c0.4,0.7,0.6,1.5,0.6,2.3c0,0.8-0.2,1.5-0.6,2.3
		c-0.4,0.7-1,1.3-1.7,1.7c-0.7,0.4-1.5,0.6-2.3,0.6s-1.5-0.2-2.3-0.6c-0.7-0.4-1.3-1-1.7-1.7c-0.4-0.7-0.6-1.5-0.6-2.3
		c0-0.8,0.2-1.5,0.6-2.3c0.4-0.7,1-1.3,1.7-1.7C195.7,5.8,196.4,5.6,197.2,5.6L197.2,5.6L197.2,5.6z M197.2,6.3
		c-0.6,0-1.3,0.2-1.9,0.5s-1.1,0.8-1.4,1.4s-0.5,1.2-0.5,1.9c0,0.6,0.2,1.3,0.5,1.9c0.3,0.6,0.8,1.1,1.4,1.4
		c0.6,0.3,1.2,0.5,1.9,0.5s1.3-0.2,1.9-0.5c0.6-0.3,1.1-0.8,1.4-1.4c0.3-0.6,0.5-1.2,0.5-1.9c0-0.6-0.2-1.3-0.5-1.9
		s-0.8-1.1-1.4-1.4C198.4,6.5,197.8,6.3,197.2,6.3L197.2,6.3L197.2,6.3z M195.2,12.6V7.7h1.7c0.6,0,1,0,1.3,0.1
		c0.2,0.1,0.5,0.2,0.6,0.5c0.2,0.2,0.2,0.5,0.2,0.7c0,0.4-0.1,0.7-0.4,0.9c-0.2,0.3-0.6,0.4-1,0.4c0.2,0.1,0.3,0.2,0.4,0.3
		c0.2,0.2,0.4,0.5,0.7,1l0.6,1h-1l-0.4-0.8c-0.3-0.6-0.6-1-0.8-1.1c-0.1-0.1-0.4-0.2-0.6-0.2H196v2.1L195.2,12.6L195.2,12.6
		L195.2,12.6z M196,9.9h1c0.4,0,0.8-0.1,0.9-0.2c0.2-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1,0-0.4-0.1-0.8-0.1H196L196,9.9L196,9.9L196,9.9z"/>
</g>
<rect fill={fill} x="216.2" width="2.6" height="50"/>
<g fill={fill}>
	<path d="M233.2,12.3c0-4,1.4-6.7,6.2-6.7c4.7,0,6.2,2.8,6.2,6.7v4.3h-2.6v-4.3c0-2.4-1-4.3-3.6-4.3c-2.8,0-3.6,1.8-3.6,4.3v26.2
		c0,2.5,0.8,4.3,3.6,4.3c2.6,0,3.6-1.9,3.6-4.3v-4.8h2.6v4.8c0,4-1.5,6.7-6.2,6.7c-4.8,0-6.2-2.8-6.2-6.7V12.3z"/>
	<path d="M255,44.6V28.2l-5.5-22.1h2.7l4.1,18.3l4.1-18.3h2.8l-5.6,22.1v16.4H255z"/>
	<path d="M267.3,12.3c0-4,1.4-6.7,6.2-6.7c4.7,0,6.2,2.8,6.2,6.7v4.3h-2.6v-4.3c0-2.4-1-4.3-3.6-4.3c-2.8,0-3.6,1.8-3.6,4.3v26.2
		c0,2.5,0.8,4.3,3.6,4.3c2.6,0,3.6-1.9,3.6-4.3v-4.8h2.6v4.8c0,4-1.5,6.7-6.2,6.7c-4.8,0-6.2-2.8-6.2-6.7V12.3z"/>
	<path d="M285.7,6.2h2.6v36h6.4v2.5h-9.1V6.2z"/>
	<path d="M299.7,6.2h2.6v38.5h-2.6V6.2z"/>
	<path d="M312.1,32.9v11.8h-2.6V6.2h2.8l8.9,32.4c-0.1-6.7-0.3-13.9-0.3-20.6V6.2h2.6v38.5h-2.8l-8.9-32.3
		C311.9,19,312.1,26.1,312.1,32.9z"/>
	<path d="M340,26.4h-3.6V24h6.2v14.5c0,4-1.4,6.7-6.2,6.7c-4.8,0-6.2-2.7-6.2-6.7V12.3c0-4,1.4-6.7,6.2-6.7c4.7,0,6.2,2.7,6.2,6.7
		v4.3H340v-4.3c0-2.5-0.9-4.3-3.6-4.3c-2.8,0-3.6,1.8-3.6,4.3v26.3c0,2.5,0.8,4.3,3.6,4.3c2.7,0,3.6-1.7,3.6-4.3V26.4z"/>
	<path d="M359.1,6.2h2.6v38.5h-2.6V6.2z"/>
	<path d="M368.9,6.2h5.6c5.3,0,6.5,2.7,6.5,6.7v8.5c0,3.3-1.4,5.8-4.7,6.4l6.4,16.9h-2.9c-2-5.6-4.1-11.2-6-16.8h-2.3v16.8h-2.6V6.2
		z M371.6,25.5h2.8c3.2,0,4.1-1.6,4.1-4.1v-8.5c0-2.5-0.8-4.1-4.1-4.1h-2.8V25.5z"/>
	<path d="M390.1,42.1h7.9v2.5h-10.6V6.2H398v2.5h-7.9V24h6.8v2.4h-6.8V42.1z"/>
	<path d="M403.9,6.2h2.6v36h6.4v2.5h-9.1V6.2z"/>
	<path d="M430.2,44.6h-2.8l-1.2-9.2h-6.3l-1.2,9.2H416l5.5-38.5h3.2L430.2,44.6z M420.2,33h5.8l-2.9-22.6L420.2,33z"/>
	<path d="M438,32.9v11.8h-2.6V6.2h2.8l8.9,32.4c-0.1-6.7-0.3-13.9-0.3-20.6V6.2h2.6v38.5h-2.8l-8.9-32.3C437.8,19,438,26.1,438,32.9
		z"/>
	<path d="M456.4,6.2h5.8c5.3,0,6.7,2.7,6.7,6.7V38c0,4-1.3,6.7-6.6,6.7h-5.9V6.2z M459,42.1h3.1c3.2,0,4.1-1.6,4.1-4.1V12.8
		c0-2.5-0.9-4.1-4.1-4.1H459V42.1z"/>
	<path d="M485.6,6.2h5.8c5.3,0,6.7,2.7,6.7,6.7V38c0,4-1.3,6.7-6.6,6.7h-5.9V6.2z M488.3,42.1h3.1c3.2,0,4.1-1.6,4.1-4.1V12.8
		c0-2.5-0.9-4.1-4.1-4.1h-3.1V42.1z"/>
	<path d="M517.2,44.6h-2.8l-1.2-9.2H507l-1.2,9.2h-2.7l5.5-38.5h3.2L517.2,44.6z M507.2,33h5.8l-2.9-22.6L507.2,33z"/>
	<path d="M531.3,16.6v-4.2c0-2.5-0.7-4.4-3.5-4.4c-2.9,0-3.5,1.9-3.5,4.4v3.2c0,9.6,9.6,5.4,9.6,18.2v4.7c0,4-1.1,6.8-6.1,6.8
		c-5.1,0-6.2-2.8-6.2-6.8v-4.8h2.6v4.8c0,2.5,0.7,4.4,3.5,4.4c2.9,0,3.5-1.8,3.5-4.4v-4.7c0-11.5-9.6-7-9.6-18.2v-3.2
		c0-4,1.1-6.8,6.2-6.8c5,0,6.1,2.8,6.1,6.8v4.2H531.3z"/>
	<path d="M542.9,44.6h-2.6V6.2h2.6V24h7.3V6.2h2.6v38.5h-2.6V26.4h-7.3V44.6z"/>
	<path d="M559.9,6.2h5.7c5.1,0,6.5,2,6.5,5.9v8.1c0,2.6-0.9,4.3-3.2,5c2.6,0.7,3.5,2.4,3.5,5.2v8c0,4-1.2,6.3-6.5,6.3h-6V6.2z
		 M562.6,24h2.9c3.1,0,4-1.4,4-3.8v-7.8c0-2.5-0.9-3.7-3.9-3.7h-3V24z M562.6,42.1h3.2c3.2,0,4-1.3,4-3.8v-8c0-2.3-0.9-3.8-4-3.8
		h-3.2V42.1z"/>
	<path d="M590.9,12.3v26.2c0,4-1.4,6.7-6.2,6.7c-4.8,0-6.2-2.8-6.2-6.7V12.3c0-4,1.4-6.7,6.2-6.7C589.5,5.6,590.9,8.4,590.9,12.3z
		 M588.3,38.5V12.3c0-2.5-0.8-4.3-3.6-4.3c-2.8,0-3.6,1.8-3.6,4.3v26.2c0,2.5,0.8,4.3,3.6,4.3C587.5,42.8,588.3,41,588.3,38.5z"/>
	<path d="M609.8,44.6H607l-1.2-9.2h-6.3l-1.2,9.2h-2.7l5.5-38.5h3.2L609.8,44.6z M599.7,33h5.8l-2.9-22.6L599.7,33z"/>
	<path d="M615,6.2h5.6c5.3,0,6.5,2.7,6.5,6.7v8.5c0,3.3-1.4,5.8-4.7,6.4l6.4,16.9h-2.9c-2-5.6-4.1-11.2-6-16.8h-2.3v16.8H615V6.2z
		 M617.6,25.5h2.8c3.2,0,4.1-1.6,4.1-4.1v-8.5c0-2.5-0.8-4.1-4.1-4.1h-2.8V25.5z"/>
	<path d="M633.5,6.2h5.8c5.3,0,6.7,2.7,6.7,6.7V38c0,4-1.3,6.7-6.6,6.7h-5.9V6.2z M636.2,42.1h3.1c3.2,0,4.1-1.6,4.1-4.1V12.8
		c0-2.5-0.9-4.1-4.1-4.1h-3.1V42.1z"/>
</g>

  </LogoSvg>
);

export default Logo;
