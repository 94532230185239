// CONSTANT ACTION TYPES

// used by most dashboards
export const PANEL_EXPAND_TOGGLE = "PANEL_EXPAND_TOGGLE";

export const INSIGHT_EXPAND_TOGGLE = "INSIGHT_EXPAND_TOGGLE";

export const UI_CONTAINER_TOGGLE = "UI_CONTAINER_TOGGLE";

export const AMPM_EXPAND_TOGGLE = "AMPM_EXPAND_TOGGLE";

//used by sustrans and other tracker dashboards
export const UPDATE_FILTERED_BIKE_ID_LIST = "UPDATE_FILTERED_BIKE_ID_LIST";
export const UPDATE_FILTER_INPUT_VALUE = "UPDATE_FILTER_INPUT_VALUE";
export const UPDATE_AVAILABLE_DATES = "UPDATE_AVAILABLE_DATES";
export const SET_DATE_FILTER_ENVELOPE = "SET_DATE_FILTER_ENVELOPE";
export const DATE_PICKER_TOGGLE = "DATE_PICKER_TOGGLE";
export const UPDATE_FILE_LAST_MODIFIED = "UPDATE_FILE_LAST_MODIFIED";

//used by the standard dashboard
export const AGE_EXPAND_TOGGLE = "AGE_EXPAND_TOGGLE";
export const GENDER_EXPAND_TOGGLE = "GENDER_EXPAND_TOGGLE";
export const BIKETYPE_EXPAND_TOGGLE = "BIKETYPE_EXPAND_TOGGLE";