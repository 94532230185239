import React from "react";
import { connect } from "react-redux";
import { uiContainerToggle } from "src/constants/dashUI-actions";
import { setFilter } from "kepler.gl/actions";
import styled from "styled-components";
import { dbColors } from "src/constants/dbColors.js";
import { get } from "src/utils/gets";

import PanelCrossIcon from "src/icon/panel-cross";
import PanelArrowIcon from "src/icon/panel-arrow";

const getCyclistFilterActiveStatus = (a) =>
  get(
    ["dashboard", "dbUiState", "uiContainers", "cyclistFilterUi", "active"],
    a
  );

const MainFilterDiv = styled.div`
  width: 14em;
  height: fit-content;
  min-height: 45px;
  display: block;
  position: relative;
  margin: 0.6em 0em 0em 1em;
  padding-left: 0.8em;

  font-family: inherit;
  background-color: ${dbColors.background};
  color: ${dbColors.highlight};
  opacity: 0.9;
  border-radius: 7px;

  cursor: pointer;
  overflow: hidden;
`;

const FilterUiHeader = styled.p`
  font-size: large;
  padding: 0;
  margin: 10px 0px;
`;

const FilterOptions_List = styled.ul`
  padding: 0;
  margin: 0.8em 0em;

  list-style-type: none;
`;

const FilterOption = styled.li`
  margin: 0.4em 0em;
  &:hover {
    font-weight: bold;
  }
`;

const ToggleIcon_Container = styled.div`
  height: 45px;
  width: 45px;
  position: absolute;
  right: 0px;
  top: 0px;

  cursor: pointer;
`;

class CyclistFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  //   bgHelper = () => {
  //     if (this.props.dashboard.dbUiState !== undefined) {
  //       if (this.props.dashboard.dbUiState.ampmUi.active === true) {
  //         return background_active;
  //       } else {
  //         return background_notActive;
  //       }
  //     }
  //     return;
  //   };

  //   fillHelper = () => {
  //     if (this.props.dashboard.dbUiState !== undefined) {
  //       if (this.props.dashboard.dbUiState.ampmUi.active === true) {
  //         return fill_active;
  //       } else {
  //         return fill_notActive;
  //       }
  //     }
  //     return;
  //   };

  //   uiVisibilityHelper = () => {
  //     if (this.props.keplerGl.map1 === undefined) {
  //       return "block";
  //     } else {
  //       // console.log(this.props.keplerGl.map1.uiState.currentModal);
  //       if (this.props.keplerGl.map1.uiState.currentModal === "exportImage") {
  //         return "none";
  //       } else {
  //         return "block";
  //       }
  //     }
  //   };

  //   ampmDisplay = () => {
  //     if (this.props.keplerGl.map1 === undefined) {
  //       return "-";
  //     } else {
  //       if (this.props.dashboard.dbUiState.ampmUi.active === true) {
  //         return "-";
  //       } else {
  //         if (this.props.keplerGl.map1.visState.filters.length > 0) {
  //           var value = this.props.keplerGl.map1.visState.filters[0].value;
  //           if (value[0] === 0 && value[1] === 1) {
  //             return "ALL";
  //           } else if (value[0] === 0 && value[1] === 0) {
  //             return "AM";
  //           } else if (value[0] === 1 && value[1] === 1) {
  //             return "PM";
  //           } else {
  //             return "-";
  //           }
  //         } else {
  //           return "-";
  //         }
  //       }
  //     }
  //   };

  //   ampmOptionOnClick = (a, b) => {
  //     this.props.uiContainerToggle(cyclistFilterUi);
  //     this.props.setFilter(0, "value", [a, b]);
  //   };

  render() {
    return (
      //JSX code for component
      <MainFilterDiv>
        <FilterUiHeader>All Cyclists</FilterUiHeader>
        {getCyclistFilterActiveStatus(this.props) && (
          <FilterOptions_List>
            <FilterOption>All Cyclists</FilterOption>
            <FilterOption>Cycling Ireland Members</FilterOption>
            <FilterOption>AXA Ride Leaders</FilterOption>
          </FilterOptions_List>
        )}
        <ToggleIcon_Container
          onClick={() => this.props.uiContainerToggle("cyclistFilterUi")}
        >
          {getCyclistFilterActiveStatus(this.props) ? (
            <PanelCrossIcon fill={dbColors.highlight} />
          ) : (
            <PanelArrowIcon fill={dbColors.highlight} />
          )}
        </ToggleIcon_Container>
      </MainFilterDiv>
    );
  }
}

/* 
<div
        className={"ampmToggle"}
        style={{
          width: "6em",
          height: this.props.dashboard.dbUiState.uiContainers.cyclistFilterUi.active
            ? "170px"
            : "45px",
          transition: "height 0.15s",
          borderRadius: "7px",
          margin: 0,
          backgroundColor: this.bgHelper(),
          color: this.fillHelper(),
          borderStyle: "none",
          borderWidth: "1px",
          fontFamily: "Roboto, sans-serif",
          opacity: 0.9,
          cursor: "pointer",
          overflow: "hidden",
          display: this.uiVisibilityHelper()
          // :hover {
          //   background-color: #284357;
          //   cursor: pointer;
          // }
        }}
      >
        <div
          className={"ampmOptionsContainer"}
          style={{
            width: "70px",
            height: "180px",
            float: "left",
            clear: "none",
            position: "absolute",
            overflow: "hidden"
          }}
        >
          <AmpmOption>{this.ampmDisplay()}</AmpmOption>
          <AmpmOption onClick={() => this.ampmOptionOnClick(0, 1)}>
            ALL
          </AmpmOption>
          <AmpmOption onClick={() => this.ampmOptionOnClick(0, 0)}>
            AM
          </AmpmOption>
          <AmpmOption onClick={() => this.ampmOptionOnClick(1, 1)}>
            PM
          </AmpmOption>
        </div>
        <div
          className={"uiContainerToggleContainer"}
          onClick={() => this.props.uiContainerToggle()}
          style={{
            width: "30px",
            margin: "0px 0px 0px 70px",
            float: "left",
            clear: "none",
            height: "fit-content"
          }}
        >
          {this.props.dashboard.dbUiState.uiContainers.cyclistFilterUi.active ? (
            <PanelCrossIcon fill={fill_active} />
          ) : (
            <PanelArrowIcon fill={fill_notActive} />
          )}
        </div>
      </div>
*/

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { setFilter, uiContainerToggle })(
  CyclistFilter
);
