import React from "react";

const BrakingIcon = ({
  style = {
    float: "left",
    clear: "none",
    margin: "7px 0px 7px 10px"
  },
  fill = "#ffffff",
  width = "30px",
  className = "",
  viewBox = "0 0 250 250",
}) => (
  <svg
  width={width}
  style={style}
  height={width}
  viewBox={viewBox}
  style={style}
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
<g>
	<path fill={fill} d="M124.63,7.26c1.29,0,2.56,0.34,3.68,0.99l95.65,55.22c2.27,1.31,3.68,3.75,3.68,6.37v110.45c0,2.62-1.41,5.06-3.68,6.37
		l-95.65,55.22c-1.12,0.64-2.39,0.99-3.68,0.99c-1.29,0-2.56-0.34-3.68-0.99L25.3,186.66c-2.27-1.31-3.68-3.75-3.68-6.37V69.84
		c0-2.62,1.41-5.06,3.68-6.37l95.65-55.22C122.07,7.6,123.34,7.26,124.63,7.26 M124.63-0.1c-2.54,0-5.08,0.66-7.36,1.97L21.62,57.1
		c-4.55,2.63-7.36,7.49-7.36,12.74v110.45c0,5.26,2.8,10.12,7.36,12.74l95.65,55.22c2.28,1.31,4.82,1.97,7.36,1.97
		s5.08-0.66,7.36-1.97l95.65-55.22c4.55-2.63,7.36-7.49,7.36-12.74V69.84c0-5.26-2.8-10.12-7.36-12.74L131.99,1.87
		C129.71,0.56,127.17-0.1,124.63-0.1L124.63-0.1z"/>
</g>
<g>
	<g>
		<path fill={fill} d="M124.99,176.84c-28.55,0-51.77-23.22-51.77-51.77c0-28.55,23.22-51.77,51.77-51.77s51.77,23.22,51.77,51.77
			C176.76,153.62,153.54,176.84,124.99,176.84z M124.99,80.66c-24.49,0-44.41,19.92-44.41,44.41s19.92,44.41,44.41,44.41
			c24.49,0,44.41-19.92,44.41-44.41S149.48,80.66,124.99,80.66z"/>
	</g>
	<g>
		<g>
			<path fill={fill} d="M127.7,137.98h-5.41c-0.97,0-1.77-0.76-1.83-1.73l-2.21-40.54c-0.06-1.05,0.78-1.93,1.83-1.93h9.82
				c1.05,0,1.88,0.88,1.83,1.93l-2.2,40.54C129.48,137.22,128.67,137.98,127.7,137.98z M118.62,154.54v-9.47
				c0-1.01,0.82-1.83,1.83-1.83h9.1c1.01,0,1.83,0.82,1.83,1.83v9.47c0,1.01-0.82,1.83-1.83,1.83h-9.1
				C119.44,156.37,118.62,155.55,118.62,154.54z"/>
		</g>
	</g>
	<g>
		<path fill={fill} d="M78.09,176.84c-0.97,0-1.93-0.38-2.66-1.13c-13.12-13.69-20.34-31.67-20.34-50.63c0-18.97,7.23-36.95,20.35-50.64
			c1.41-1.47,3.73-1.52,5.2-0.11c1.47,1.41,1.52,3.73,0.11,5.2c-11.8,12.31-18.31,28.49-18.31,45.55c0,17.06,6.5,33.23,18.3,45.54
			c1.41,1.47,1.36,3.8-0.11,5.2C79.92,176.5,79.01,176.84,78.09,176.84z"/>
	</g>
	<g>
		<path fill={fill} d="M171.89,176.85c-0.92,0-1.83-0.34-2.55-1.02c-1.47-1.41-1.52-3.73-0.11-5.2c11.8-12.31,18.31-28.49,18.31-45.55
			c0-17.06-6.5-33.24-18.31-45.56c-1.41-1.47-1.36-3.8,0.11-5.2c1.47-1.4,3.79-1.36,5.2,0.11c13.13,13.69,20.36,31.68,20.36,50.65
			c0,18.97-7.23,36.95-20.35,50.64C173.82,176.47,172.85,176.85,171.89,176.85z"/>
	</g>
</g>
</svg>

);

export default BrakingIcon;
