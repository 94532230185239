export const dbColors = {
  highlight: "#1a3c8c",
  highlight_hover: "#4e74cc",
  background: "#ffffff",
  greyedout: "#747474",
  li_hover_background: '#e4e4e4',
  li_active_background: '#b3b3b3'
};

/*All data for the dashboard UI panel is stored here
and exported as a single object to be distributed to the
panel components in app.js*/
